
 /* ===================================================================
  * # footer - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */
 
footer {
  font-family: "montserrat-regular", sans-serif;
  font-size: 14px;
  padding: 6.6rem 0 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.2);
  background-color: #B37715;
}

footer a, footer a:visited {
    color: #ffad1f;
}

footer a:hover, footer a:focus {
    color: #FFFFFF;
}

.footer-logo {
    display: inline-block;
}

.footer-site-logo {
    display: block;
    width: 130px;
    font-family: "tajawal", sans-serif;
    font-size: medium;
}

.footer-social {
    list-style: none;
    margin-top: 3rem;
    margin-left: 0;
    font-family: "montserrat-regular", sans-serif;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: .3rem;
}

.footer-social:after {
    content: "";
    display: table;
    clear: both;
}

.footer-social li {
    display: inline-block;
    padding-left: 0;
    margin-right: .9rem;
    line-height: 3.9rem;
    vertical-align: middle;
}

.footer-social li::after {
    content: "|";
    color: rgba(255, 255, 255, 0.15);
    padding-left: .9rem;
}

.footer-social li i {
    font-size: 1.7rem;
    line-height: 3rem;
    vertical-align: middle;
}

.footer-social li span {
    margin-left: .6rem;
}

.footer-social li a {
    color: #FFFFFF;
}

.footer-social li:last-child {
    margin-right: 0;
}

.footer-social li:last-child::after {
    display: none;
}

.footer-bottom {
    margin-top: 7.2rem;
    text-align: center;
}

.footer-bottom .copyright span {
    display: inline-block;
}

.footer-bottom .copyright span::after {
    content: "|";
    display: inline-block;
    padding: 0 1rem 0 1.2rem;
    color: rgba(255, 255, 255, 0.1);
}

.footer-bottom .copyright span:last-child::after {
    display: none;
}


/* ------------------------------------------------------------------- 
 * ## go to top
 * ------------------------------------------------------------------- */

.go-top {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 600;
    display: none;
}

.go-top a, .go-top a:visited {
    text-decoration: none;
    border: 0 none;
    display: block;
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    background: black;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.go-top a i, .go-top a:visited i {
    font-size: 12px;
    line-height: inherit;
}

.go-top a:hover, .go-top a:focus {
    color: #FFFFFF;
}


/* ------------------------------------------------------------------- 
 * responsive:
 * footer 
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 600px) {
    .footer-social li {
        margin-right: 1.8rem;
    }
    .footer-social li span, .footer-social li::after {
        display: none;
    }
    .footer-social li i {
        font-size: 2.4rem;
    }
    .footer-bottom {
        padding-bottom: .6rem;
    }
    .footer-bottom .copyright span {
        display: block;
    }
    .footer-bottom .copyright span::after {
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    .footer-site-logo {
        width: 100px;
    }
    .footer-social li {
        margin-right: 1.2rem;
    }
    .footer-social li i {
        font-size: 2.2rem;
    }
}
