@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*!
 * iconmonstr iconic font v1.2.0
 * Created by Alexander Kahlkopf - http://iconmonstr.com - @iconmonstr
 * License - http://iconmonstr.com/license
 */
@font-face {
  font-family: iconmonstr-iconic-font;
  src: url(/static/media/iconmonstr-iconic-font.853bd54f.woff2) format("woff2"),
    url(/static/media/iconmonstr-iconic-font.a32fa19f.woff) format("woff"),
    url(/static/media/iconmonstr-iconic-font.8fcd573a.ttf) format("truetype");
}
.im {
  display: inline-block;
  font: normal normal normal 24px/1 "iconmonstr-iconic-font";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.im-angle-right:before {
  content: "\e001";
}
.im-angle-left:before {
  content: "\e002";
}
.im-angle-down:before {
  content: "\e003";
}
.im-angle-up:before {
  content: "\e004";
}
.im-angle-right-circle:before {
  content: "\e005";
}
.im-angle-left-circle:before {
  content: "\e006";
}
.im-angle-down-circle:before {
  content: "\e007";
}
.im-angle-up-circle:before {
  content: "\e008";
}
.im-arrow-right:before {
  content: "\e009";
}
.im-arrow-left:before {
  content: "\e00a";
}
.im-arrow-down:before {
  content: "\e00b";
}
.im-arrow-up:before {
  content: "\e00c";
}
.im-arrow-right-circle:before {
  content: "\e00d";
}
.im-arrow-left-circle:before {
  content: "\e00e";
}
.im-arrow-down-circle:before {
  content: "\e00f";
}
.im-arrow-up-circle:before {
  content: "\e010";
}
.im-care-right:before {
  content: "\e011";
}
.im-care-left:before {
  content: "\e012";
}
.im-care-down:before {
  content: "\e013";
}
.im-care-up:before {
  content: "\e014";
}
.im-forbidden:before {
  content: "\e015";
}
.im-printer:before {
  content: "\e016";
}
.im-menu:before {
  content: "\e017";
}
.im-menu-list:before {
  content: "\e018";
}
.im-quote-left:before {
  content: "\e019";
}
.im-quote-right:before {
  content: "\e01a";
}
.im-bell:before {
  content: "\e01b";
}
.im-bell-off:before {
  content: "\e01c";
}
.im-bookmark:before {
  content: "\e01d";
}
.im-briefcase:before {
  content: "\e01e";
}
.im-calendar:before {
  content: "\e01f";
}
.im-photo-camera:before {
  content: "\e020";
}
.im-video-camera:before {
  content: "\e021";
}
.im-microphone:before {
  content: "\e022";
}
.im-check-mark:before {
  content: "\e023";
}
.im-check-mark-circle:before {
  content: "\e024";
}
.im-check-square-o:before {
  content: "\e025";
}
.im-check-square:before {
  content: "\e026";
}
.im-check-square-i:before {
  content: "\e027";
}
.im-square-o:before {
  content: "\e028";
}
.im-radio-button-circle-o:before {
  content: "\e029";
}
.im-radio-button-circle:before {
  content: "\e02a";
}
.im-circle-o:before {
  content: "\e02b";
}
.im-clock-o:before {
  content: "\e02c";
}
.im-cloud:before {
  content: "\e02d";
}
.im-cloud-download:before {
  content: "\e02e";
}
.im-cloud-upload:before {
  content: "\e02f";
}
.im-code:before {
  content: "\e030";
}
.im-speech-bubble:before {
  content: "\e031";
}
.im-speech-bubble-comment:before {
  content: "\e032";
}
.im-speech-bubble-comments:before {
  content: "\e033";
}
.im-copy:before {
  content: "\e034";
}
.im-credit-card:before {
  content: "\e035";
}
.im-crown:before {
  content: "\e036";
}
.im-database:before {
  content: "\e037";
}
.im-computer:before {
  content: "\e038";
}
.im-download:before {
  content: "\e039";
}
.im-upload:before {
  content: "\e03a";
}
.im-pencil:before {
  content: "\e03b";
}
.im-edit-off:before {
  content: "\e03c";
}
.im-play:before {
  content: "\e03d";
}
.im-pause:before {
  content: "\e03e";
}
.im-stop:before {
  content: "\e03f";
}
.im-eject:before {
  content: "\e040";
}
.im-previous:before {
  content: "\e041";
}
.im-next:before {
  content: "\e042";
}
.im-loop:before {
  content: "\e043";
}
.im-random:before {
  content: "\e044";
}
.im-menu-dot-h:before {
  content: "\e045";
}
.im-menu-dot-v:before {
  content: "\e046";
}
.im-mail:before {
  content: "\e047";
}
.im-info:before {
  content: "\e048";
}
.im-warning:before {
  content: "\e049";
}
.im-question:before {
  content: "\e04a";
}
.im-link:before {
  content: "\e04b";
}
.im-unlink:before {
  content: "\e04c";
}
.im-external-link:before {
  content: "\e04d";
}
.im-eye:before {
  content: "\e04e";
}
.im-eye-off:before {
  content: "\e04f";
}
.im-file:before {
  content: "\e050";
}
.im-file-o:before {
  content: "\e051";
}
.im-files-o:before {
  content: "\e052";
}
.im-video:before {
  content: "\e053";
}
.im-audio:before {
  content: "\e054";
}
.im-picture-o:before {
  content: "\e055";
}
.im-flag:before {
  content: "\e056";
}
.im-folder:before {
  content: "\e057";
}
.im-folder-open:before {
  content: "\e058";
}
.im-smiley-o:before {
  content: "\e059";
}
.im-frown-o:before {
  content: "\e05a";
}
.im-gear:before {
  content: "\e05b";
}
.im-globe:before {
  content: "\e05c";
}
.im-heart:before {
  content: "\e05d";
}
.im-home:before {
  content: "\e05e";
}
.im-inbox:before {
  content: "\e05f";
}
.im-key:before {
  content: "\e060";
}
.im-lock:before {
  content: "\e061";
}
.im-lock-open:before {
  content: "\e062";
}
.im-task-o:before {
  content: "\e063";
}
.im-filter:before {
  content: "\e064";
}
.im-light-bulb:before {
  content: "\e065";
}
.im-flash:before {
  content: "\e066";
}
.im-map-o:before {
  content: "\e067";
}
.im-location:before {
  content: "\e068";
}
.im-maximize:before {
  content: "\e069";
}
.im-minimize:before {
  content: "\e06a";
}
.im-fullscreen:before {
  content: "\e06b";
}
.im-mobile:before {
  content: "\e06c";
}
.im-phone:before {
  content: "\e06d";
}
.im-coin:before {
  content: "\e06e";
}
.im-banknote:before {
  content: "\e06f";
}
.im-paper-clip:before {
  content: "\e070";
}
.im-bar-chart:before {
  content: "\e071";
}
.im-plus:before {
  content: "\e072";
}
.im-minus:before {
  content: "\e073";
}
.im-plus-circle:before {
  content: "\e074";
}
.im-minus-circle:before {
  content: "\e075";
}
.im-undo:before {
  content: "\e076";
}
.im-redo:before {
  content: "\e077";
}
.im-rocket:before {
  content: "\e078";
}
.im-rss:before {
  content: "\e079";
}
.im-magnifier:before {
  content: "\e07a";
}
.im-magnifier-plus:before {
  content: "\e07b";
}
.im-magnifier-minus:before {
  content: "\e07c";
}
.im-share:before {
  content: "\e07d";
}
.im-shield:before {
  content: "\e07e";
}
.im-shopping-cart:before {
  content: "\e07f";
}
.im-sign-in:before {
  content: "\e080";
}
.im-sign-out:before {
  content: "\e081";
}
.im-spinner:before {
  content: "\e082";
}
.im-star:before {
  content: "\e083";
}
.im-star-half:before {
  content: "\e084";
}
.im-star-o:before {
  content: "\e085";
}
.im-sync:before {
  content: "\e086";
}
.im-table:before {
  content: "\e087";
}
.im-window-o:before {
  content: "\e088";
}
.im-windows-o:before {
  content: "\e089";
}
.im-thumb-up:before {
  content: "\e08a";
}
.im-thumb-down:before {
  content: "\e08b";
}
.im-x-mark:before {
  content: "\e08c";
}
.im-x-mark-circle:before {
  content: "\e08d";
}
.im-trash-can:before {
  content: "\e08e";
}
.im-user-male:before {
  content: "\e08f";
}
.im-user-female:before {
  content: "\e090";
}
.im-user-circle:before {
  content: "\e091";
}
.im-users:before {
  content: "\e092";
}
.im-volume:before {
  content: "\e093";
}
.im-volume-off:before {
  content: "\e094";
}
.im-wifi:before {
  content: "\e095";
}
.im-tools:before {
  content: "\e096";
}
.im-dashboard:before {
  content: "\e097";
}
.im-archive:before {
  content: "\e098";
}
.im-save:before {
  content: "\e099";
}
.im-floppy-disk:before {
  content: "\e09a";
}
.im-sitemap:before {
  content: "\e09b";
}
.im-toggle:before {
  content: "\e09c";
}
.im-tag:before {
  content: "\e09d";
}
.im-tags:before {
  content: "\e09e";
}
.im-wizard:before {
  content: "\e09f";
}
.im-book:before {
  content: "\e0a0";
}
.im-fire:before {
  content: "\e0a1";
}
.im-id-card:before {
  content: "\e0a2";
}
.im-note-o:before {
  content: "\e0a3";
}
.im-control-panel:before {
  content: "\e0a4";
}
.im-facebook:before {
  content: "\e0a5";
}
.im-facebook-like:before {
  content: "\e0a6";
}
.im-twitter:before {
  content: "\e0a7";
}
.im-amazon:before {
  content: "\e0a8";
}
.im-android-os:before {
  content: "\e0a9";
}
.im-apple-os:before {
  content: "\e0aa";
}
.im-windows-os:before {
  content: "\e0ab";
}
.im-linux-os:before {
  content: "\e0ac";
}
.im-chrome:before {
  content: "\e0ad";
}
.im-ie:before {
  content: "\e0ae";
}
.im-edge:before {
  content: "\e0af";
}
.im-firefox:before {
  content: "\e0b0";
}
.im-safari:before {
  content: "\e0b1";
}
.im-opera:before {
  content: "\e0b2";
}
.im-behance:before {
  content: "\e0b3";
}
.im-blogger:before {
  content: "\e0b4";
}
.im-flickr:before {
  content: "\e0b5";
}
.im-github:before {
  content: "\e0b6";
}
.im-google-plus:before {
  content: "\e0b7";
}
.im-instagram:before {
  content: "\e0b8";
}
.im-linkedin:before {
  content: "\e0b9";
}
.im-pinterest:before {
  content: "\e0ba";
}
.im-skype:before {
  content: "\e0bb";
}
.im-snapchat:before {
  content: "\e0bc";
}
.im-soundcloud:before {
  content: "\e0bd";
}
.im-stackoverflow:before {
  content: "\e0be";
}
.im-stumbleupon:before {
  content: "\e0bf";
}
.im-tumblr:before {
  content: "\e0c0";
}
.im-xing:before {
  content: "\e0c1";
}
.im-youtube:before {
  content: "\e0c2";
}
.im-reddit:before {
  content: "\e0c3";
}
.im-vimeo:before {
  content: "\e0c4";
}
.im-vk:before {
  content: "\e0c5";
}
.im-whatsapp:before {
  content: "\e0c6";
}
.im-paypal:before {
  content: "\e0c7";
}
.im-twitch:before {
  content: "\e0c8";
}
.im-drop:before {
  content: "\e0c9";
}
.im-sun:before {
  content: "\e0ca";
}
.im-certificate-o:before {
  content: "\e0cb";
}
.im-graduation-hat:before {
  content: "\e0cc";
}
.im-store:before {
  content: "\e0cd";
}
.im-pin:before {
  content: "\e0ce";
}
.im-navigation:before {
  content: "\e0cf";
}
.im-keyboard:before {
  content: "\e0d0";
}
.im-cursor:before {
  content: "\e0d1";
}
.im-monitor-o:before {
  content: "\e0d2";
}
.im-laptop-o:before {
  content: "\e0d3";
}
.im-power:before {
  content: "\e0d4";
}
.im-pie-chart:before {
  content: "\e0d5";
}
.im-line-chart-up:before {
  content: "\e0d6";
}
.im-clock:before {
  content: "\e0d7";
}
.im-flip-chart-o:before {
  content: "\e0d8";
}
.im-gift:before {
  content: "\e0d9";
}
.im-leaf:before {
  content: "\e0da";
}
.im-bug:before {
  content: "\e0db";
}
.im-coffee:before {
  content: "\e0dc";
}
.im-diamond-o:before {
  content: "\e0dd";
}
.im-bell-active:before {
  content: "\e0de";
}
.im-history:before {
  content: "\e0df";
}
.im-gamepad:before {
  content: "\e0e0";
}
.im-binoculars:before {
  content: "\e0e1";
}
.im-paperplane:before {
  content: "\e0e2";
}
.im-wrench:before {
  content: "\e0e3";
}
.im-newspaper-o:before {
  content: "\e0e4";
}
.im-lifebuoy:before {
  content: "\e0e5";
}
.im-fingerprint:before {
  content: "\e0e6";
}
.im-date-o:before {
  content: "\e0e7";
}
.im-network:before {
  content: "\e0e8";
}
.im-target:before {
  content: "\e0e9";
}
.im-user-settings:before {
  content: "\e0ea";
}
.im-radio:before {
  content: "\e0eb";
}
.im-bank:before {
  content: "\e0ec";
}
.im-calculator:before {
  content: "\e0ed";
}
.im-battery-empty:before {
  content: "\e0ee";
}
.im-battery:before {
  content: "\e0ef";
}
.im-battery-full:before {
  content: "\e0f0";
}
.im-check-mark-circle-o:before {
  content: "\e0f1";
}
.im-x-mark-circle-o:before {
  content: "\e0f2";
}
.im-cube:before {
  content: "\e0f3";
}
.im-cubes:before {
  content: "\e0f4";
}
.im-warning-circle:before {
  content: "\e0f5";
}
.im-timer:before {
  content: "\e0f6";
}
.im-hashtag:before {
  content: "\e0f7";
}
.im-pointer:before {
  content: "\e0f8";
}
.im-paintbrush:before {
  content: "\e0f9";
}
.im-server:before {
  content: "\e0fa";
}
.im-car:before {
  content: "\e0fb";
}
.im-edit:before {
  content: "\e0fc";
}
.im-flask:before {
  content: "\e0fd";
}
.im-language:before {
  content: "\e0fe";
}
.im-anchor:before {
  content: "\e0ff";
}
.im-trophy:before {
  content: "\e100";
}
.im-umbrella:before {
  content: "\e101";
}
.im-cc-amex:before {
  content: "\e102";
}
.im-cc-visa:before {
  content: "\e103";
}
.im-cc-mastercard:before {
  content: "\e104";
}
.im-cc-paypal:before {
  content: "\e105";
}
.im-cc-amazon:before {
  content: "\e106";
}
.im-cc-bitcoin:before {
  content: "\e107";
}

/* =================================================================== 
 *
 *  Hola v1.0 Main Stylesheet
 *  10-02-2017
 *  ------------------------------------------------------------------
 *
 *  TOC:
 *  # webfonts and iconfonts
 *  # base style overrides
 *      ## links
 *  # typography & general theme styles
 *      ## lists
 *      ## responsive video container
 *      ## floated image
 *      ## tables
 *      ## spacing
 *      ## pace.js styles - minimal
 *  # forms
 *      ## style placeholder text
 *      ## Change Autocomplete styles in Chrome
 *  # buttons
 *  # additional components
 *      ## alert box
 *      ## additional typo styles
 *      ## skillbars
 *  # common and reusable theme styles 
 *      ## section intro
 *      ## blog-list
 *  # testimonials
 *  # blog
 *  # cta
 *  # stats
 *  # contact
 *  # footer
 *      ## go to top
 *  # blog styles
 *      ## blog page header
 *      ## blog content
 *      ## comments

 Colour scheme: https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=2b92ef&secondary.color=FFAD1F
 *
 * =================================================================== */

/* ===================================================================
 * # webfonts and iconfonts - (_document-setup.scss)
 *
 * ------------------------------------------------------------------- */

/* ===================================================================
  * # base style overrides - (_document-setup.scss)
  *
  * ------------------------------------------------------------------- */

html {
  font-size: 10px;
}

@media only screen and (max-width: 400px) {
  html {
    font-size: 9.375px;
  }
}

html,
body {
  height: 100%;
}

body {
  background: #ffffff;
  font-family: "barlow", serif;
  font-size: 1.6rem;
  line-height: 1.875;
  color: #5f5f5f;
  margin: 0;
  padding: 0;
}

/* ------------------------------------------------------------------- 
  * ## links - (_document-setup.scss) 
  * ------------------------------------------------------------------- */

a {
  color: #ffbd52;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus,
a:active {
  color: #191a3f;
}

a:focus {
  outline: none;
}

/* ===================================================================
  * # typography & general theme styles - (_document-setup.scss) 
  * 
  * ------------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6,
.h01,
.h02,
.h03,
.h04,
.h05,
.h06 {
  font-family: "montserrat-medium", sans-serif;
  color: #0d0a0b;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

h1,
.h01,
h2,
.h02,
h3,
.h03,
h4,
.h04 {
  margin-top: 6rem;
  margin-bottom: 1.8rem;
}

@media only screen and (max-width: 600px) {
  h1,
  .h01,
  h2,
  .h02,
  h3,
  .h03,
  h4,
  .h04 {
    margin-top: 5.1rem;
  }
}

h5,
.h05,
h6,
.h06 {
  margin-top: 4.2rem;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 600px) {
  h5,
  .h05,
  h6,
  .h06 {
    margin-top: 3.6rem;
    margin-bottom: 0.9rem;
  }
}

h1,
.h01 {
  font-family: "montserrat-bold", sans-serif;
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}

@media only screen and (max-width: 600px) {
  h1,
  .h01 {
    font-size: 3.3rem;
    letter-spacing: -0.07rem;
  }
}

h2,
.h02 {
  font-size: 3rem;
  line-height: 1.3;
}

h3,
.h03 {
  font-size: 2.4rem;
  line-height: 1.25;
}

h4,
.h04 {
  font-size: 2.1rem;
  line-height: 1.286;
}

h5,
.h05 {
  font-size: 1.6rem;
  line-height: 1.3125;
}

h6,
.h06 {
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
}

p img {
  margin: 0;
}

p.lead {
  font-family: "montserrat-regular", sans-serif;
  font-size: 2rem;
  line-height: 1.8;
  margin-bottom: 3.6rem;
  color: #0d0a0b;
}

@media only screen and (max-width: 800px) {
  p.lead {
    font-size: 1.8rem;
  }
}

em,
i,
strong,
b {
  font-size: inherit;
  line-height: inherit;
  font-style: normal;
  font-weight: normal;
}

em,
i {
  font-family: "barlow", serif;
  font-style: italic;
}

strong,
b {
  font-family: "barlow", serif;
  font-weight: 700;
}

small {
  font-size: 1.2rem;
  line-height: inherit;
}

blockquote {
  margin: 3.9rem 0;
  padding-left: 4.5rem;
  position: relative;
}

blockquote:before {
  content: "\201C";
  font-size: 10rem;
  line-height: 0px;
  margin: 0;
  color: rgba(0, 0, 0, 0.15);
  font-family: arial, sans-serif;
  position: absolute;
  top: 3.6rem;
  left: 0;
}

blockquote p {
  font-family: "montserrat-regular", sans-serif;
  padding: 0;
  font-size: 2.1rem;
  line-height: 1.857;
  color: #0d0a0b;
}

blockquote cite {
  display: block;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 1.5;
}

blockquote cite:before {
  content: "\2014 \0020";
}

blockquote cite a,
blockquote cite a:visited {
  color: #6c6c6c;
  border: none;
}

abbr {
  font-family: "librebaskerville-bold", serif;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.05rem;
  color: #6c6c6c;
}

var,
kbd,
samp,
code,
pre {
  font-family: Consolas, "Andale Mono", Courier, "Courier New", monospace;
}

pre {
  padding: 2.4rem 3rem 3rem;
  background: #f1f1f1;
  overflow-x: auto;
}

code {
  font-size: 1.4rem;
  margin: 0 0.2rem;
  padding: 0.3rem 0.6rem;
  white-space: nowrap;
  background: #f1f1f1;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
}

pre > code {
  display: block;
  white-space: pre;
  line-height: 2;
  padding: 0;
  margin: 0;
}

pre.prettyprint > code {
  border: none;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
  text-decoration: none;
}

mark {
  background: #ffd900;
  color: #000000;
}

hr {
  border: solid rgba(0, 0, 0, 0.1);
  border-width: 1px 0 0;
  clear: both;
  margin: 2.4rem 0 1.5rem;
  height: 0;
}

/* ------------------------------------------------------------------- 
  * ## Lists - (_document-setup.scss)  
  * ------------------------------------------------------------------- */

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

li {
  display: list-item;
}

ol,
ul {
  margin-left: 1.7rem;
}

ul li {
  padding-left: 0.4rem;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin: 0.6rem 0 0.6rem 1.7rem;
}

ul.disc li {
  display: list-item;
  list-style: none;
  padding: 0 0 0 0.8rem;
  position: relative;
}

ul.disc li::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #862121;
  position: absolute;
  left: -17px;
  top: 11px;
  vertical-align: middle;
}

dt {
  margin: 0;
  color: #862121;
}

dd {
  margin: 0 0 0 2rem;
}

/* ------------------------------------------------------------------- 
  * ## responsive video container - (_document-setup)
  * ------------------------------------------------------------------- */

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ------------------------------------------------------------------- 
  * ## floated image - (_document-setup)  
  * ------------------------------------------------------------------- */

img.pull-right {
  margin: 1.5rem 0 0 3rem;
}

img.pull-left {
  margin: 1.5rem 3rem 0 0;
}

/* ------------------------------------------------------------------- 
  * ## tables - (_document-setup.scss)  
  * ------------------------------------------------------------------- */

table {
  border-width: 0;
  width: 100%;
  max-width: 100%;
  font-family: "librebaskerville-regular", serif;
}

th,
td {
  padding: 1.5rem 3rem;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}

th {
  color: #0d0a0b;
  font-family: "montserrat-bold", sans-serif;
}

td {
  line-height: 1.5;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* ------------------------------------------------------------------- 
  * ## Spacing - (_document-setup.scss)  
  * ------------------------------------------------------------------- */

button,
.btn {
  margin-bottom: 1.2rem;
}

fieldset {
  margin-bottom: 1.5rem;
}

input,
textarea,
select,
pre,
blockquote,
figure,
table,
p,
ul,
ol,
dl,
form,
.video-container,
.ss-custom-select {
  margin-bottom: 3rem;
}

/* ------------------------------------------------------------------- 
  * ## pace.js styles - minimal  - (_document-setup.scss)
  * ------------------------------------------------------------------- */

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #cf8402;
  position: fixed;
  z-index: 900;
  top: 0;
  right: 100%;
  width: 100%;
  height: 4px;
}

.oldie .pace {
  display: none;
}

/* ===================================================================
  * preloader - (_preloader-2.scss)
  *
  * ------------------------------------------------------------------- */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #276fb3;
  /* preloader fix colours! */
  z-index: 800;
  height: 100%;
  width: 100%;
}

.no-js #preloader,
.oldie #preloader {
  display: none;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  padding: 0;
  background-color: #b37715;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

/* ===================================================================
  * # forms - (_forms.scss)
  *
  * ------------------------------------------------------------------- */

fieldset {
  border: none;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  display: block;
  height: 6rem;
  padding: 1.5rem 0;
  border: 0;
  outline: none;
  color: #333333;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.5rem;
  line-height: 3rem;
  max-width: 100%;
  background: transparent;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

.ss-custom-select {
  position: relative;
  padding: 0;
}

.ss-custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  margin: 0;
  line-height: 3rem;
  vertical-align: middle;
}

.ss-custom-select select option {
  padding-left: 2rem;
  padding-right: 2rem;
}

.ss-custom-select select::-ms-expand {
  display: none;
}

.ss-custom-select::after {
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 8px;
  width: 8px;
  margin-top: -7px;
  pointer-events: none;
  position: absolute;
  right: 2.4rem;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
}

/* IE9 and below */

.oldie .ss-custom-select::after {
  display: none;
}

textarea {
  min-height: 25rem;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  color: #000000;
  border-bottom: 2px solid black;
}

label,
legend {
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 0.9rem;
  line-height: 1.714;
  color: #0d0a0b;
  display: block;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

label > .label-text {
  display: inline-block;
  margin-left: 1rem;
  font-family: "montserrat-regular", sans-serif;
  line-height: inherit;
}

label > input[type="checkbox"],
label > input[type="radio"] {
  margin: 0;
  position: relative;
  top: 0.15rem;
}

/* ------------------------------------------------------------------- 
  * ## style placeholder text - (_forms.scss)
  * ------------------------------------------------------------------- */

::-webkit-input-placeholder {
  color: #6c6c6c;
}

:-moz-placeholder {
  color: #6c6c6c;
  /* Firefox 18- */
}

::-moz-placeholder {
  color: #6c6c6c;
  /* Firefox 19+ */
}

:-ms-input-placeholder {
  color: #6c6c6c;
}

.placeholder {
  color: #6c6c6c !important;
}

/* ------------------------------------------------------------------- 
  * ## Change Autocomplete styles in Chrome - (_forms.scss)
  * ------------------------------------------------------------------- */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #cf3e3e;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

/* ===================================================================
  * # buttons - (_buttons.scss)
  *
  * ------------------------------------------------------------------- */

.btn,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  font-family: "montserrat-semibold", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  height: 5.4rem;
  line-height: calc(5.4rem - 0.6rem);
  padding: 0 3rem;
  margin: 0 0.3rem 1.2rem 0;
  color: #151515;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  background-color: #c5c5c5;
  border: 0.3rem solid #c5c5c5;
}

.btn:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.btn:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  background-color: #b8b8b8;
  border-color: #b8b8b8;
  color: #000000;
  outline: 0;
}

/* button primary
  * ------------------------------------------------- */

.btn.btn--primary,
button.btn--primary,
input[type="submit"].btn--primary,
input[type="reset"].btn--primary,
input[type="button"].btn--primary {
  background: #cf8402;
  border-color: #cf8402;
  color: #ffffff;
}

.btn.btn--primary:hover,
button.btn--primary:hover,
input[type="submit"].btn--primary:hover,
input[type="reset"].btn--primary:hover,
input[type="button"].btn--primary:hover,
.btn.btn--primary:focus,
button.btn--primary:focus,
input[type="submit"].btn--primary:focus,
input[type="reset"].btn--primary:focus,
input[type="button"].btn--primary:focus {
  background: #c77e00;
  border-color: #c77e00;
}

/* button modifiers
  * ------------------------------------------------- */

.btn.full-width,
button.full-width {
  width: 100%;
  margin-right: 0;
}

.btn--medium,
button.btn--medium {
  height: 5.7rem !important;
  line-height: calc(5.7rem - 0.6rem) !important;
}

.btn--large,
button.btn--large {
  height: 6rem !important;
  line-height: calc(6rem - 0.6rem) !important;
}

.btn--stroke,
button.btn--stroke {
  background: transparent !important;
  border: 0.3rem solid #000000;
  color: #000000;
}

.btn--stroke:hover,
button.btn--stroke:hover {
  border: 0.3rem solid #862121;
  color: #862121;
}

.btn--pill,
button.btn--pill {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 1000px !important;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* =================================================================== 
  * # additional components - (_others.scss)
  *
  * ------------------------------------------------------------------- */

/* ------------------------------------------------------------------- 
  * ## alert box - (_alert-box.scss)
  * ------------------------------------------------------------------- */

.alert-box {
  padding: 2.1rem 4rem 2.1rem 3rem;
  position: relative;
  margin-bottom: 3rem;
  border-radius: 3px;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.5rem;
  line-height: 1.6;
}

.alert-box__close {
  position: absolute;
  right: 1.8rem;
  top: 1.8rem;
  cursor: pointer;
}

.alert-box__close.im {
  font-size: 12px;
}

.alert-box--error {
  background-color: #ffd1d2;
  color: #e65153;
}

.alert-box--success {
  background-color: #c8e675;
  color: #758c36;
}

.alert-box--info {
  background-color: #d7ecfb;
  color: #4a95cc;
}

.alert-box--notice {
  background-color: #fff099;
  color: #bba31b;
}

/* ------------------------------------------------------------------- 
  * ## additional typo styles - (_additional-typo.scss)
  * ------------------------------------------------------------------- */

/* drop cap 
  * ----------------------------------------------- */

.drop-cap:first-letter {
  float: left;
  margin: 0;
  padding: 1.5rem 0.6rem 0 0;
  font-size: 8.4rem;
  font-family: "montserrat-bold", sans-serif;
  line-height: 6rem;
  text-indent: 0;
  background: transparent;
  color: #000000;
}

/* line definition style 
  * ----------------------------------------------- */

.lining dt,
.lining dd {
  display: inline;
  margin: 0;
}

.lining dt + dt:before,
.lining dd + dt:before {
  content: "\A";
  white-space: pre;
}

.lining dd + dd:before {
  content: ", ";
}

.lining dd + dd:before {
  content: ", ";
}

.lining dd:before {
  content: ": ";
  margin-left: -0.2em;
}

/* dictionary definition style 
  * ----------------------------------------------- */

.dictionary-style dt {
  display: inline;
  counter-reset: definitions;
}

.dictionary-style dt + dt:before {
  content: ", ";
  margin-left: -0.2em;
}

.dictionary-style dd {
  display: block;
  counter-increment: definitions;
}

.dictionary-style dd:before {
  content: counter(definitions, decimal) ". ";
}

/** 
  * Pull Quotes
  * -----------
  * markup:
  *
  * <aside class="pull-quote">
  *		<blockquote>
  *			<p></p>
  *		</blockquote>
  *	</aside>
  *
  * --------------------------------------------------------------------- */

.pull-quote {
  position: relative;
  padding: 2.1rem 3rem 2.1rem 0px;
}

.pull-quote:before,
.pull-quote:after {
  height: 1em;
  position: absolute;
  font-size: 10rem;
  font-family: Arial, Sans-Serif;
  color: rgba(0, 0, 0, 0.15);
}

.pull-quote:before {
  content: "\201C";
  top: -3.6rem;
  left: 0;
}

.pull-quote:after {
  content: "\201D";
  bottom: 3.6rem;
  right: 0;
}

.pull-quote blockquote {
  margin: 0;
}

.pull-quote blockquote:before {
  content: none;
}

/** 
  * Stats Tab
  * ---------
  * markup:
  *
  * <ul class="stats-tabs">
  *		<li><a href="#">[value]<em>[name]</em></a></li>
  *	</ul>
  *
  * Extend this object into your markup.
  *
  * --------------------------------------------------------------------- */

.stats-tabs {
  padding: 0;
  margin: 3rem 0;
}

.stats-tabs li {
  display: inline-block;
  margin: 0 1.5rem 3rem 0;
  padding: 0 1.5rem 0 0;
  border-right: 1px solid rgba(13, 10, 11, 0.15);
}

.stats-tabs li:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

.stats-tabs li a {
  display: inline-block;
  font-size: 2.5rem;
  font-family: "montserrat-semibold", sans-serif;
  border: none;
  color: #151515;
}

.stats-tabs li a:hover {
  color: #862121;
}

.stats-tabs li a em {
  display: block;
  margin: 0.6rem 0 0 0;
  font-size: 1.4rem;
  font-family: "montserrat-regular", sans-serif;
  color: #6c6c6c;
}

/* ------------------------------------------------------------------- 
  * ## skillbars - (_skillbars.scss)
  * ------------------------------------------------------------------- */

.skill-bars {
  list-style: none;
  margin: 6rem 0 3rem;
}

.skill-bars li {
  height: 0.6rem;
  background: #c9c9c9;
  width: 100%;
  margin-bottom: 6.9rem;
  padding: 0;
  position: relative;
}

.skill-bars li strong {
  position: absolute;
  left: 0;
  top: -3rem;
  font-family: "montserrat-extrabold", sans-serif;
  color: #0d0a0b;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.skill-bars li .progress {
  background: #000000;
  background-image: linear-gradient(to right, #191a3f, #2d462c);
  position: relative;
  height: 100%;
}

.skill-bars li .progress span {
  position: absolute;
  right: 0;
  top: -3.6rem;
  display: block;
  font-family: "montserrat-regular", sans-serif;
  color: #ffffff;
  font-size: 1.1rem;
  line-height: 1;
  background: #000000;
  padding: 0.6rem 0.6rem;
  border-radius: 3px;
}

.skill-bars li .progress span::after {
  position: absolute;
  left: 50%;
  bottom: -5px;
  margin-left: -5px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #000000;
  content: "";
}

.skill-bars li .percent5 {
  width: 5%;
}

.skill-bars li .percent10 {
  width: 10%;
}

.skill-bars li .percent15 {
  width: 15%;
}

.skill-bars li .percent20 {
  width: 20%;
}

.skill-bars li .percent25 {
  width: 25%;
}

.skill-bars li .percent30 {
  width: 30%;
}

.skill-bars li .percent35 {
  width: 35%;
}

.skill-bars li .percent40 {
  width: 40%;
}

.skill-bars li .percent45 {
  width: 45%;
}

.skill-bars li .percent50 {
  width: 50%;
}

.skill-bars li .percent55 {
  width: 55%;
}

.skill-bars li .percent60 {
  width: 60%;
}

.skill-bars li .percent65 {
  width: 65%;
}

.skill-bars li .percent70 {
  width: 70%;
}

.skill-bars li .percent75 {
  width: 75%;
}

.skill-bars li .percent80 {
  width: 80%;
}

.skill-bars li .percent85 {
  width: 85%;
}

.skill-bars li .percent90 {
  width: 90%;
}

.skill-bars li .percent95 {
  width: 95%;
}

.skill-bars li .percent100 {
  width: 100%;
}

/* ===================================================================
  * # common and reusable theme styles  - (_common-styles.scss)
  *
  * ------------------------------------------------------------------- */

.wide {
  max-width: 1400px;
}

.narrow {
  max-width: 800px;
}

/* ------------------------------------------------------------------- 
  * ## section intro - (_common-styles.scss)
  * ------------------------------------------------------------------- */

.section-intro {
  text-align: center;
  position: relative;
}

.section-intro h1 {
  font-family: "montserrat-semibold", sans-serif;
  font-size: 4.8rem;
  line-height: 1.375;
  margin-top: 0;
  color: rgb(0, 101, 188);
}

.section-intro h3 {
  font-family: "montserrat-semibold", sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  margin-top: 0;
  margin-bottom: 0;
}

.section-intro.has-bottom-sep {
  padding-bottom: 1.5rem;
  position: relative;
}

.section-intro.has-bottom-sep::after {
  display: inline-block;
  height: 1px;
  width: 240px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* ------------------------------------------------------------------- 
  * responsive:
  * section-intro
  * ------------------------------------------------------------------- */

@media only screen and (max-width: 800px) {
  .section-intro h1 {
    font-size: 4.2rem;
  }
  .section-intro h3 {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 600px) {
  .section-intro h1 {
    font-size: 3.6rem;
  }
  .section-intro h3 {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 400px) {
  .section-intro h1 {
    font-size: 3.3rem;
  }
  .section-intro h3 {
    font-size: 1.6rem;
  }
}

/* ===================================================================
  * # cta - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */

.s-cta {
  margin-top: -9rem;
  padding-top: 0;
  padding-bottom: 9rem;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.cta__content {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cta__content h2 a {
  color: #0d0a0b;
}

.cta__content span {
  color: #191a3f;
}

/* ===================================================================
  * # blog styles - (_blog.scss)
  *
  * ------------------------------------------------------------------- */

article.blog-single {
  background-color: #ffffff;
}

article.blog-single .blog-content {
  padding-top: 7.2rem;
  padding-bottom: 12rem;
}

/* -------------------------------------------------------------------
  * ## blog page header
  * ------------------------------------------------------------------- */

.page-header {
  background-color: #0d0a0b;
  background-repeat: no-repeat;
  background-position: center, center;
  padding-top: 20.4rem;
  padding-bottom: 7.2rem;
  position: relative;
  color: rgba(255, 255, 255, 0.5);
  min-height: 50.4rem;
}

.page-header--single {
  padding-top: 22.8rem;
  padding-bottom: 7.2rem;
  text-align: center;
}

.page-header::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  background-color: #0d0a0b;
}

.page-header::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
  /* IE6-9 */
}

.page-header__content {
  font-family: "montserrat-regular", sans-serif;
  font-size: 2rem;
  line-height: 1.8;
  position: relative;
  max-width: 900px;
  z-index: 2;
}

.page-header__content p {
  padding-right: 3rem;
}

.page-header__info {
  margin-bottom: 1.2rem;
}

.page-header__info .div:last-child {
  margin-right: 0;
}

.page-header__cat,
.page-header__date {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 0.3rem;
}

.page-header__cat {
  font-size: 1.4rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
  color: rgba(255, 255, 255, 0.8);
  font-family: "montserrat-bold", sans-serif;
}

.page-header__cat a {
  color: rgba(255, 255, 255, 0.8);
}

.page-header__cat a:hover,
.page-header__cat a:focus,
.page-header__cat a:active {
  color: white;
}

.page-header__cat a::after {
  content: ", ";
}

.page-header__cat a:last-child::after {
  display: none;
}

.page-header__date {
  font-family: "montserrat-light", sans-serif;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.4);
}

.page-header__date a {
  color: rgba(255, 255, 255, 0.4);
}

.page-header__date a:hover,
.page-header__date a:focus,
.page-header__date a:active {
  color: white;
}

.page-header__title {
  color: #ffffff;
  position: relative;
  font-family: "librebaskerville-bold", serif;
  font-size: 6.6rem;
  line-height: 1.227;
  margin-top: 0;
  margin-bottom: 3.6rem;
}

.page-header__title a {
  color: #ffffff;
}

.page-header__meta {
  list-style: none;
  margin-left: 0;
  padding-top: 2.4rem;
  font-size: 1.6rem;
  position: relative;
}

.page-header__meta::before {
  display: block;
  content: "";
  height: 1px;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -50px;
}

.page-header__meta li {
  display: inline-block;
  padding-left: 0;
  margin-right: 2rem;
}

.page-header__meta li::last-child {
  margin-right: 0;
}

.page-header__meta span,
.page-header__meta a {
  color: #ffffff;
}

.page-header__btn {
  border-color: #ffffff !important;
  color: #ffffff;
  letter-spacing: 0.25rem;
}

.page-header__btn:hover,
.page-header__btn:focus {
  background: #ffffff !important;
  color: #000000;
}

/* ------------------------------------------------------------------- 
  * responsive:
  * blog page header
  * ------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {
  .page-header__content {
    max-width: 800px;
    font-size: 1.7rem;
  }
  .page-header__cat {
    font-size: 1.3rem;
  }
  .page-header__title {
    font-size: 6rem;
  }
}

@media only screen and (max-width: 900px) {
  .page-header__content {
    max-width: 700px;
  }
}

@media only screen and (max-width: 800px) {
  .page-header--single {
    padding-top: 18rem;
  }
  .page-header__title {
    font-size: 5.4rem;
  }
}

@media only screen and (max-width: 600px) {
  .page-header {
    padding-top: 18rem;
  }
  .page-header__content p {
    padding-right: 0;
  }
  .page-header__title {
    font-size: 4.8rem;
  }
}

@media only screen and (max-width: 500px) {
  .page-header__title {
    font-size: 4.2rem;
  }
}

@media only screen and (max-width: 400px) {
  .page-header__content {
    font-size: 1.6rem;
    line-height: 1.875;
  }
  .page-header__title {
    font-size: 3.8rem;
  }
}

/* -------------------------------------------------------------------
  * ## blog content - _blog.scss
  * ------------------------------------------------------------------- */

.blog-content-wrap {
  background-color: #ffffff;
  padding-top: 4.2rem;
  padding-bottom: 12rem;
}

.blog-content {
  max-width: 1000px;
}

.blog-content__main {
  padding-left: 6rem;
  padding-right: 6rem;
}

.blog-content__main img {
  max-width: 1000px;
  margin: 3rem 0 3rem -6rem;
}

/* post tags
  * ------------------------------------------------- */

.blog-content__tags {
  margin-top: 6rem;
  font-family: "montserrat-regular", sans-serif;
}

.blog-content__tags span {
  font-family: "montserrat-bold", sans-serif;
  color: #0d0a0b;
  position: relative;
  padding-right: 55px;
  margin-right: 1.5rem;
  margin-top: 1.2rem;
}

.blog-content__tags span::after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  right: 0;
}

.blog-content__tags .blog-content__tag-list {
  display: inline-block;
  padding-right: 0;
  margin-right: 0;
}

.blog-content__tags .blog-content__tag-list::after {
  display: none;
}

.blog-content__tags .blog-content__tag-list a {
  display: inline-block;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.3rem;
  line-height: inherit;
  color: #797979;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 0 10px;
}

.blog-content__tags .blog-content__tag-list a:hover,
.blog-content__tags .blog-content__tag-list a:focus {
  background-color: #0d0a0b;
  border-color: #0d0a0b;
  color: #ffffff;
}

.blog-content__tags .blog-content__tag-list a:last-child {
  margin-right: 0;
}

/* blog single page navigation
  * ------------------------------------------------- */

.blog-content__pagenav {
  margin-top: 7.8rem;
  padding-top: 1.8rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.blog-content__nav {
  padding-right: 220px;
}

.blog-content__nav:after {
  content: "";
  display: table;
  clear: both;
}

.blog-content__nav span {
  display: block;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.6rem;
  color: #797979;
  margin-bottom: 1.2rem;
  transition: all 0.3s ease-in-out;
}

.blog-content__nav a {
  font-family: "montserrat-bold", sans-serif;
  font-size: 2.2rem;
  line-height: 1.5;
  color: #0d0a0b;
}

.blog-content__nav a:hover span,
.blog-content__nav a:focus span {
  color: #0d0a0b;
}

.blog-content__prev,
.blog-content__next {
  width: 50%;
  float: left;
  padding-right: 2rem;
}

.blog-content__all {
  text-align: right;
  width: 210px;
  position: absolute;
  top: 2.1rem;
  right: 0;
}

.blog-content__all .im {
  font-size: 1.4rem;
  line-height: 1;
  margin-right: 8px;
  position: relative;
  top: 2px;
}

/* blog entries navigation
  * ------------------------------------------------- */

.blog-entries-nav {
  text-align: center;
  max-width: 600px;
  padding-top: 3.6rem;
}

.blog-entries-nav .btn {
  margin-right: 3rem;
  min-width: 150px;
}

.blog-entries-nav .btn:last-child {
  margin-right: 0;
}

.blog-entries-nav .im {
  font-size: 1.3rem;
  line-height: 1;
  position: relative;
  top: 2px;
}

/* ------------------------------------------------------------------- 
  * responsive:
  * blog content
  * ------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {
  .blog-content {
    max-width: 900px;
  }
  .blog-content__main {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .blog-content__main img {
    max-width: 900px;
    margin: 3rem 0 3rem -5rem;
  }
}

@media only screen and (max-width: 1000px) {
  .blog-content {
    max-width: 800px;
  }
  .blog-content__main {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .blog-content__main img {
    max-width: 800px;
    margin: 3rem 0 3rem -4rem;
  }
  .blog-content__prev,
  .blog-content__next {
    width: 100%;
    float: none;
    padding: 0;
    text-align: left;
    margin-bottom: 1.8rem;
  }
}

@media only screen and (max-width: 900px) {
  .blog-content__main {
    padding-left: 15px;
    padding-right: 15px;
  }
  .blog-content__main img {
    max-width: 100%;
    margin: 3rem 0 3rem;
  }
}

@media only screen and (max-width: 800px) {
  .blog-content__nav {
    padding-right: 0;
  }
  .blog-content__all {
    position: static;
    text-align: left;
    width: auto;
    margin-top: 1.8rem;
  }
}

@media only screen and (max-width: 600px) {
  .blog-content__prev,
  .blog-content__next,
  .blog-content__all {
    text-align: center;
  }
  .blog-content__main {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .blog-entries-nav .btn {
    margin-right: 0;
    min-width: none;
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .blog-content__main {
    padding-left: 0;
    padding-right: 0;
  }
  .blog-content__all .btn {
    width: 100%;
  }
}

/* -------------------------------------------------------------------
  * ## comments - _blog.scss
  * ------------------------------------------------------------------- */

.comments-wrap {
  background-color: #f0f0f0;
  padding: 8.4rem 0 9rem;
}

/* comments
  * -------------------------------------------------- */

#comments {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  max-width: 940px;
}

#comments h3 {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

#comments ol,
#comments ul {
  list-style: none;
}

.commentlist {
  margin: 3rem 0 5.4rem;
  padding: 0;
}

.commentlist > .comment {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 1.8rem 0;
  padding-left: 14%;
}

.comment__avatar {
  position: absolute;
  left: 0;
  display: block;
  height: 6rem;
  width: 6rem;
}

.comment__avatar img {
  margin-top: 0.6rem;
  height: 6rem;
  width: 6rem;
  border-radius: 100%;
}

.comment__info cite {
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  color: #0d0a0b;
}

.comment__meta {
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.45rem;
  line-height: 3rem;
  font-style: normal;
  display: block;
  color: rgba(0, 0, 0, 0.7);
}

.comment__meta .reply {
  font-family: "montserrat-bold", sans-serif;
}

.comment__meta .sep {
  margin: 0 5px;
}

.comment__text {
  clear: both;
  margin: 1.8rem 0 0 0;
  padding: 0;
}

.comment ul.children {
  margin: 0;
  padding: 1.8rem 0 0 0;
}

.comment ul.children li {
  padding-left: 5%;
  padding-top: 1.8rem;
}

/* comments form
  * ---------------------------- */

#comments .respond {
  padding-top: 4.2rem;
}

#comments form {
  padding-top: 3rem;
}

#comments form .form-field {
  margin-bottom: 3.9rem;
}

/* ------------------------------------------------------------------- 
  * responsive:
  * comments
  * ------------------------------------------------------------------- */

@media only screen and (max-width: 800px) {
  #comments form button {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  #comments h3 {
    text-align: center;
  }
  .commentlist > .comment {
    padding-left: 0;
  }
  .commentlist .comment__avatar {
    display: none;
  }
}

/* ===================================================================
  * # header styles - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */

.s-header {
  width: 100%;
  height: 72px;
  background-color: transparent;
  position: absolute;
  top: 0;
  z-index: 500;
}

.s-header.offset {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  transition: all 1s ease-in-out;
}

.s-header.scrolling {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.s-header.sticky {
  position: fixed;
  top: 0;
  background-color: #0065bc !important;
  opacity: 0;
  visibility: hidden;
  z-index: 600;
}

.s-header.sticky .header-logo a {
  margin: 0;
}

.s-header.sticky .header-nav-wrap {
  top: 0;
}

.s-header.sticky.scrolling {
  opacity: 1;
  visibility: visible;
}

/* -------------------------------------------------------------------
 * ## header logo - (_site-layout.css) 
 * ------------------------------------------------------------------- */

.header-logo {
  display: inline-block;
  position: absolute;
  margin: 0;
  padding: 0;
  left: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 501;
}

.header-logo a {
  display: block;
  font-family: "tajawal", sans-serif;
  font-size: large;
  margin: 9px 0 0 0;
  padding: 0;
  outline: 0;
  border: none;
  transition: all 0.3s ease-in-out;
}

/* ------------------------------------------------------------------- 
 * ## mobile menu toggle - (_site-layout.css) 
 * ------------------------------------------------------------------- */

.header-menu-toggle {
  display: none;
  position: absolute;
  right: 29px;
  top: 18px;
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease-in-out;
}

.header-menu-toggle:hover,
.header-menu-toggle:focus {
  color: #ffffff;
}

.header-menu-toggle span {
  display: block;
  width: 24px;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  left: 9px;
  top: 50%;
  right: auto;
  bottom: auto;
  background-color: white;
  transition: all 0.5s ease-in-out;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.header-menu-toggle span::before,
.header-menu-toggle span::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: inherit;
  position: absolute;
  left: 0;
  transition: all 0.5s ease-in-out;
}

.header-menu-toggle span::before {
  top: -9px;
}

.header-menu-toggle span::after {
  bottom: -9px;
}

.s-header.sticky .header-menu-toggle {
  top: 12px;
}

.header-menu-toggle.is-clicked span {
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.1s ease-in-out;
}

.header-menu-toggle.is-clicked span::before,
.header-menu-toggle.is-clicked span::after {
  background-color: white;
}

.header-menu-toggle.is-clicked span::before {
  top: 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.header-menu-toggle.is-clicked span::after {
  bottom: 0;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

/* ------------------------------------------------------------------- 
 * ## navigation - (_site-layout.scss) 
 * ------------------------------------------------------------------- */

.header-nav-wrap {
  font-family: "montserrat-bold", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  position: absolute;
  top: 9px;
  right: 50px;
}

.header-nav {
  display: inline-block;
  list-style: none;
  margin: 0;
  height: 72px;
}

.header-nav li {
  display: inline-block;
  padding-left: 0;
  margin-right: 2rem;
}

.header-nav li a {
  display: block;
  line-height: 72px;
  color: #ffffff;
}

/* .header-nav li.current a {
    font-family: "montserrat-extrabold", sans-serif;
    color: #af2b2b;
} */

/* ------------------------------------------------------------------- 
 * responsive:
 * header
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 800px) {
  .header-logo {
    left: 40px;
  }
  .header-nav-wrap {
    display: none;
    height: auto;
    width: 100%;
    right: auto;
    top: 0;
    left: 0;
    background-color: #000000;
    padding: 120px 40px 21px;
  }
  .header-nav-wrap .header-nav {
    display: block;
    height: auto;
    margin: 0 0 4.2rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
  }
  .header-nav-wrap .header-nav li {
    display: block;
    margin: 0;
    padding: 0;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.07);
  }
  .header-nav-wrap .header-nav li a {
    padding: 18px 0;
    line-height: 18px;
  }
  .header-menu-toggle {
    display: block;
  }
}

/* -------------------------------------------------------------------
 * make sure the menu is visible on larger screens
 * ------------------------------------------------------------------- */

@media only screen and (min-width: 801px) {
  .header-nav-wrap {
    display: block !important;
  }
}

 /* ===================================================================
  * # home
  *
  * ------------------------------------------------------------------- */
 
 
.s-home {
  width: 100%;
  height: 100%;
  min-height: 786px;
  background-color: transparent;
  position: relative;
  display: table;
}

.s-home .shadow-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .8;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
}

.s-home .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background-color: #111111;
}

.no-js .s-home {
  background: #0D0A0B;
}


/* ------------------------------------------------------------------- 
* ## home content - (_site-layout.scss) 
* ------------------------------------------------------------------- */

.home-content {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  padding-bottom: 19.2rem;
  position: relative;
  background-color: #2b92ef;
  /* background-image: url("../images/coffee.jpg"); */
}


.home-content h3 {
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.8rem;
  color: #ffad1f;
  text-transform: uppercase;
  letter-spacing: .5rem;
  position: relative;
  padding-left: 80px;
  margin-left: -30px;
  margin-bottom: .6rem;
}

.home-content h3::before {
  display: block;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #ffad1f;
  margin-top: -1.5px;
  position: absolute;
  left: 0;
  top: 50%;
}

.home-content h1 {
  font-family: "librebaskerville-bold", serif;
  color: #FFFFFF;
  font-size: 6rem;
  line-height: 1.35;
  margin-top: 0;
}

.home-content__main {
  padding-right: 300px;
  position: relative;
}

.home-content__buttons {
  position: absolute;
  right: 0;
  bottom: 2.1rem;
  text-align: center;
}

.home-content__buttons .btn {
  display: block;
  width: 210px;
  border-color: #FFFFFF;
  color: #FFFFFF;
  margin: 1.5rem 0 0 0;
  letter-spacing: .25rem;
  transition: all 0.5s ease-in-out;
}

.home-content__buttons .btn:hover, 
.home-content__buttons .btn:focus {
  background: #FFFFFF !important;
  color: #000000;
}

.home-content__scroll {
  position: absolute;
  left: .6rem;
  bottom: -13.2rem;
}

.home-content__scroll a {
  font-family: "montserrat-semibold", sans-serif;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: .3rem;
  color: #FFFFFF;
  position: relative;
  display: inline-block;
  line-height: 3rem;
  padding-right: 2rem;
}

.home-content__scroll a::after {
  border-bottom: 2px solid #ffad1f;
  border-right: 2px solid #ffad1f;
  content: '';
  display: block;
  height: 8px;
  width: 8px;
  margin-top: -7px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
}


/* ------------------------------------------------------------------- 
* ## home social - (_site-layout.scss) 
* ------------------------------------------------------------------- */

.home-social {
  font-family: "montserrat-bold", sans-serif;
  list-style: none;
  margin: 0;
  padding-bottom: 18rem;
  position: absolute;
  bottom: 0;
  right: 40px;
}

.home-social::after {
  display: block;
  content: "";
  height: 17.4rem;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.08);
  position: absolute;
  bottom: 0;
  right: .75rem;
}

.home-social a {
  color: white;
  transition: all 0.5s ease-in-out;
}

.home-social li {
  position: relative;
  height: 3.6rem;
}

.home-social i, .home-social span {
  position: absolute;
  top: 0;
  line-height: 3.6rem;
  transition: all 0.5s ease-in-out;
}

.home-social i {
  font-size: 1.8rem;
  right: 0;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

.home-social span {
  color: white;
  right: 3.6rem;
  font-size: 1.3rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.home-social li:hover span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.home-social li:hover i {
  -webkit-transform: scale(1);
  transform: scale(1);
}


/* animate .home-content__main
* ------------------------------------------------------------------- */

html.ss-preload .home-content__main {
  opacity: 0;
}

html.ss-loaded .home-content__main {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

html.no-csstransitions .home-content__main {
  opacity: 1;
}


/* ------------------------------------------------------------------- 
* ## home animations - (_site-layout.scss) 
* ------------------------------------------------------------------- */

/* bounce */

@-webkit-keyframes bounce {
  0%, 100% {
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
  }
  50% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  0%, 100% {
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
  }
  50% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
  }
}


/* fade in */

@-webkit-keyframes fadeIn {
  from {
      opacity: 0;
      -webkit-transform: translate3d(0, -150%, 0);
      transform: translate3d(0, -150%, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
      -webkit-transform: translate3d(0, -150%, 0);
      transform: translate3d(0, -150%, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }
}


/* fade out */

@-webkit-keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
      -webkit-transform: translate3d(0, -150%, 0);
      transform: translate3d(0, -150%, 0);
  }
}

@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
      -webkit-transform: translate3d(0, -150%, 0);
      transform: translate3d(0, -150%, 0);
  }
}


/* ------------------------------------------------------------------- 
* responsive:
* home
* ------------------------------------------------------------------- */

@media only screen and (max-width: 1500px) {
  .home-content h1 {
      font-size: 5.2rem;
  }
  .home-content__main {
      max-width: 1000px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-content h1 {
      font-size: 5rem;
  }
  .home-content br {
      display: none;
  }
  .home-content__main {
      max-width: 800px;
  }
  .home-content__buttons {
      right: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .home-content h1 {
      font-size: 4.8rem;
  }
  .home-content__main {
      padding-right: 40px;
      max-width: 680px;
  }
  .home-content__buttons {
      position: static;
      text-align: left;
  }
  .home-content__buttons .btn {
      display: inline-block;
      margin-right: .9rem;
  }
}

@media only screen and (max-width: 800px) {
  .home-content h3 {
      font-size: 1.6rem;
      padding-left: 50px;
      margin-left: -10px;
  }
  .home-content h3::before {
      width: 40px;
  }
  .home-content h1 {
      font-size: 4.4rem;
  }
  .home-content__main {
      max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .home-content {
      padding-bottom: 15rem;
  }
  .home-content h1 {
      font-size: 4.2rem;
  }
  .home-content__main {
      max-width: 380px;
      padding: 0;
  }
  .home-content__scroll {
      bottom: -9rem;
  }
  .home-social {
      padding-bottom: 10.8rem;
  }
  .home-social::after {
      height: 10.2rem;
  }
}

@media only screen and (max-width: 500px) {
  .s-home {
      min-height: 654px;
  }
  .home-content h3 {
      font-size: 1.5rem;
  }
  .home-content h1 {
      font-size: 3.8rem;
  }
  .home-content__main {
      max-width: none;
      width: 85%;
      margin-top: 150px;
  }
}

@media only screen and (max-width: 400px) {
  .home-content {
      vertical-align: middle;
  }
  .home-content h1 {
      font-size: 3.5rem;
  }
  .home-content__main {
      width: auto;
      margin-left: 25px;
      margin-right: 25px;
  }
  .home-content__buttons .btn {
      width: 100%;
  }
  .home-social {
      display: none;
  }
}

/* ===================================================================
  * # about - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */

.s-about {
  padding-top: 15rem;
  padding-bottom: 9rem;
  background-color: #f1f1f1;
  position: relative;
}

.about-content {
  margin-bottom: 3rem;
}

.about-content .left {
  padding-right: 40px;
  text-align: center;
}

.about-content .right {
  padding-left: 40px;
  text-align: center;
  background-size: 30px 30px;
}

.about-content img {
  border-radius: 50%;
  text-align: center;
  width: 75%;
}

.about-content .left img{
  padding-bottom: 3rem;
}

.about-content p {
  text-align: justify;
}

.about-content h3 {
  text-align: justify;
}




/* ------------------------------------------------------------------- 
 * responsive:
 * about
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 1000px) {
  .about-content .left {
    padding-right: 30px;
  }

  .about-content .left img {
    padding-bottom: 0;
  }

  .about-content .right {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 900px) {
  .about-content .left {
    padding-right: 20px;
  }
  .about-content .right {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .about-content {
    max-width: 600px;
  }
  .about-content .left {
    padding-right: 15px;
  }
  .about-content .right {
    padding-left: 15px;
  }
  .about-content .right .timeline {
    margin-top: 0;
  }
  .about-content .left > h3,
  .about-content .right > h3 {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .about-content .left {
    padding-right: 10px;
  }
  .about-content .right {
    padding-left: 10px;
  }
  .timeline__block {
    padding-left: 6rem;
  }
}

@media only screen and (max-width: 400px) {
  .about-content .left {
    padding-right: 0;
  }
  .about-content .right {
    padding-left: 0;
  }
  .timeline__block {
    padding-left: 5.5rem;
  }
}

/* ===================================================================
  * # works - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */

.s-works {
  padding-top: 15rem;
  padding-bottom: 15.6rem;
  background-color: #f1f1f1;
  position: relative;
}

body {
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  padding-top: 72px;
}

* {
  outline: none;
}

.inner button {
  -webkit-appearance: none;
          -moz-appearance: none;
       appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
         filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  transition: all .5s ease;
  border: none;
  background: #ffad1f;
  text-transform: uppercase;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 13px;
  cursor: cursor;
}
.inner a{
  color: black;
}

button:hover {
  background: #c77e00;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  height: 70vh;
  background-size: cover !important;
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
}

.previousButton, .nextButton {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		z-index: 10;
    background: url('https://svgshare.com/i/KbT.svg') no-repeat center center / 30px;
		width: 32px;
		height: 32px;
		text-indent: -9999px;
    cursor: pointer;
}

.previousButton:hover, .nextButton:hover {
  background: url('https://www.flaticon.com/free-icon/left-arrow_271220') no-repeat center center / 16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.slider-content .inner button{
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

@media (max-height: 500px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }    
}

@media (max-width: 640px) {
  .slider-wrapper, .slide {
    height: calc(80vh - 75px);
  }    
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }    
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

/* ===================================================================
  * # slider-animations
  *
  * ------------------------------------------------------------------- */

.slide h1 {
	transition: all 0.3s ease;
	-webkit-transform: translateY(-20px);
					transform: translateY(-20px);
	opacity: 0;
}

.slide button {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide p {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	transition-delay: .9s;
					opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	transition-delay: 1.3s;
}

.slide.animateOut h1 {
	transition-delay: .3s;
}

.slide.animateOut p {
	transition-delay: .2s;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * works
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 1700px) {
  .masonry-wrap {
    width: 1400px;
  }
  .masonry .grid-sizer,
  .masonry__brick {
    width: 350px;
  }
}

@media only screen and (max-width: 1500px) {
  .masonry-wrap {
    width: 1200px;
  }
  .masonry .grid-sizer,
  .masonry__brick {
    width: 400px;
  }
}

@media only screen and (max-width: 1300px) {
  .masonry-wrap {
    width: 999px;
  }
  .masonry .grid-sizer,
  .masonry__brick {
    width: 333px;
  }
}

@media only screen and (max-width: 1099px) {
  .masonry-wrap {
    width: 90%;
    max-width: 800px;
  }
  .masonry .grid-sizer,
  .masonry__brick {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .masonry .grid-sizer,
  .masonry__brick {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .masonry-wrap {
    width: auto;
  }
}

/* ===================================================================
  * # contact - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */

.s-contact {
  background-color: #276fb3;
  /* background-image: url("../images/laptop.jpg"); */
  background-repeat: no-repeat;
  background-position: center, center;
  padding-top: 13.2rem;
  padding-bottom: 12rem;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
}

.s-contact .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-color: #070606;
}

.contact__main img {
  /* float: right; */
  display: block;
  margin: auto;
  width: 50%;
  
}

.s-contact .section-intro h1 {
  color: #ffffff;
}

.s-contact .section-intro h3 {
  color: #ffad1f;
}

.s-contact .section-intro p {
  color: rgba(255, 255, 255, 0.5);
}

.s-contact .section-intro.has-bottom-sep::after {
  background-color: rgba(255, 255, 255, 0.1);
}

.s-contact .form-field {
  position: relative;
}

.s-contact .form-field:after {
  content: "";
  display: table;
  clear: both;
}

.s-contact .form-field label {
  font-family: "montserrat-semibold", sans-serif;
  font-size: 1.1rem;
  line-height: 2.4rem;
  position: absolute;
  bottom: -0.3rem;
  right: 0.6rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 0 2rem;
  margin: 0;
  color: #ffffff;
  background: #862121;
}

.s-contact .form-field label::after {
  content: "";
  position: absolute;
  left: -5px;
  top: 50%;
  margin-top: -6px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid ;
}

.contact__main {
  max-width: 900px;
  position: relative;
}

.contact__main h4 {
  color: #ffffff;
}

.contact__infos {
  font-size: 1.5rem;
}

.message-warning,
.message-success {
  display: none;
  background: #0d0a0b;
  font-size: 1.5rem;
  line-height: 2;
  padding: 3rem;
  margin-bottom: 3.6rem;
  width: 100%;
}

/* --------------------------------------------------------------- 
 * loader animation - (_site-layout.scss)
 * --------------------------------------------------------------- */

.s-loader {
  margin: 1.2rem auto 3rem;
  width: 70px;
  text-align: center;
  -webkit-transform: translateX(0.45rem);
  transform: translateX(0.45rem);
}

.s-loader > div {
  width: 9px;
  height: 9px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.9rem;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
  animation: bouncedelay 1.4s infinite ease-in-out both;
}

.s-loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.s-loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ------------------------------------------------------------------- 
 * responsive:
 * contact
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 800px) {
  .contact__infos {
    padding-top: 3rem;
  }
}


 /* ===================================================================
  * # footer - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */
 
footer {
  font-family: "montserrat-regular", sans-serif;
  font-size: 14px;
  padding: 6.6rem 0 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.2);
  background-color: #B37715;
}

footer a, footer a:visited {
    color: #ffad1f;
}

footer a:hover, footer a:focus {
    color: #FFFFFF;
}

.footer-logo {
    display: inline-block;
}

.footer-site-logo {
    display: block;
    width: 130px;
    font-family: "tajawal", sans-serif;
    font-size: medium;
}

.footer-social {
    list-style: none;
    margin-top: 3rem;
    margin-left: 0;
    font-family: "montserrat-regular", sans-serif;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: .3rem;
}

.footer-social:after {
    content: "";
    display: table;
    clear: both;
}

.footer-social li {
    display: inline-block;
    padding-left: 0;
    margin-right: .9rem;
    line-height: 3.9rem;
    vertical-align: middle;
}

.footer-social li::after {
    content: "|";
    color: rgba(255, 255, 255, 0.15);
    padding-left: .9rem;
}

.footer-social li i {
    font-size: 1.7rem;
    line-height: 3rem;
    vertical-align: middle;
}

.footer-social li span {
    margin-left: .6rem;
}

.footer-social li a {
    color: #FFFFFF;
}

.footer-social li:last-child {
    margin-right: 0;
}

.footer-social li:last-child::after {
    display: none;
}

.footer-bottom {
    margin-top: 7.2rem;
    text-align: center;
}

.footer-bottom .copyright span {
    display: inline-block;
}

.footer-bottom .copyright span::after {
    content: "|";
    display: inline-block;
    padding: 0 1rem 0 1.2rem;
    color: rgba(255, 255, 255, 0.1);
}

.footer-bottom .copyright span:last-child::after {
    display: none;
}


/* ------------------------------------------------------------------- 
 * ## go to top
 * ------------------------------------------------------------------- */

.go-top {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 600;
    display: none;
}

.go-top a, .go-top a:visited {
    text-decoration: none;
    border: 0 none;
    display: block;
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    background: black;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
}

.go-top a i, .go-top a:visited i {
    font-size: 12px;
    line-height: inherit;
}

.go-top a:hover, .go-top a:focus {
    color: #FFFFFF;
}


/* ------------------------------------------------------------------- 
 * responsive:
 * footer 
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 600px) {
    .footer-social li {
        margin-right: 1.8rem;
    }
    .footer-social li span, .footer-social li::after {
        display: none;
    }
    .footer-social li i {
        font-size: 2.4rem;
    }
    .footer-bottom {
        padding-bottom: .6rem;
    }
    .footer-bottom .copyright span {
        display: block;
    }
    .footer-bottom .copyright span::after {
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    .footer-site-logo {
        width: 100px;
    }
    .footer-social li {
        margin-right: 1.2rem;
    }
    .footer-social li i {
        font-size: 2.2rem;
    }
}

#stack a{
  color: #0065bc;
}

#stack a:hover{
  color: #191a3f;
}

.s-stack {
  padding-top: 15rem;
  padding-bottom: 9.6rem;
  background-color: #FFFFFF;
  position: relative;
}

.blog-list {
  margin-top: 6rem;
  margin-left: 0;
}

.blog-list article {
  margin-bottom: 7.2rem;
}

.blog-list article:nth-child(2n+1) {
  padding-right: 50px;
}

.blog-list article:nth-child(2n+2) {
  padding-left: 50px;
}

.blog-list h2 {
  font-family: "montserrat-medium", sans-serif;
  font-size: 3rem;
  line-height: 1.2;
  margin-top: .3rem;
  margin-bottom: 2.1rem;
  padding-bottom: 2.4rem;
  color: #0D0A0B;
  position: relative;
}

.blog-list h2 a {
  color: #0D0A0B;
}

.blog-list h2::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 80px;
  background-color: rgba(0, 0, 0, 0.15);
}

.blog-list .blog-date {
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.5rem;
  color: #862121;
}

.blog-list .blog-date a {
  color: #862121;
}

.blog-list .blog-date a:hover, 
.blog-list .blog-date a:focus, 
.blog-list .blog-date a:active {
  color: #000000;
}

.blog-list .blog-cat {
  font-family: "montserrat-medium", sans-serif;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: .2rem;
  color: #000000;
  margin-top: -.9rem;
}

.blog-list .blog-cat a {
  color: #000000;
}

.blog-list .blog-cat a:hover, 
.blog-list .blog-cat a:focus, 
.blog-list .blog-cat a:active {
  color: #862121;
}

.blog-list .blog-cat a::after {
  content: ", ";
}

.blog-list .blog-cat a:last-child::after {
  display: none;
}


/* ------------------------------------------------------------------- 
* responsive:
* blog-list
* ------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {
  .blog-list article:nth-child(2n+1) {
      padding-right: 40px;
  }
  .blog-list article:nth-child(2n+2) {
      padding-left: 40px;
  }
}

@media only screen and (max-width: 800px) {
  .blog-list article:nth-child(n) {
      padding: 0 15px;
  }
}

@media only screen and (max-width: 600px) {
  .blog-list article:nth-child(n) {
      padding: 0;
  }
  .blog-list h2 {
      font-size: 2.7rem;
  }
}
