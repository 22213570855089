/* =================================================================== 
 *
 *  Hola v1.0 Main Stylesheet
 *  10-02-2017
 *  ------------------------------------------------------------------
 *
 *  TOC:
 *  # webfonts and iconfonts
 *  # base style overrides
 *      ## links
 *  # typography & general theme styles
 *      ## lists
 *      ## responsive video container
 *      ## floated image
 *      ## tables
 *      ## spacing
 *      ## pace.js styles - minimal
 *  # forms
 *      ## style placeholder text
 *      ## Change Autocomplete styles in Chrome
 *  # buttons
 *  # additional components
 *      ## alert box
 *      ## additional typo styles
 *      ## skillbars
 *  # common and reusable theme styles 
 *      ## section intro
 *      ## blog-list
 *  # testimonials
 *  # blog
 *  # cta
 *  # stats
 *  # contact
 *  # footer
 *      ## go to top
 *  # blog styles
 *      ## blog page header
 *      ## blog content
 *      ## comments

 Colour scheme: https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=2b92ef&secondary.color=FFAD1F
 *
 * =================================================================== */

/* ===================================================================
 * # webfonts and iconfonts - (_document-setup.scss)
 *
 * ------------------------------------------------------------------- */

@import url("./styles/css/iconmonstr-iconic-font.min.css");
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@800&display=swap');

/* ===================================================================
  * # base style overrides - (_document-setup.scss)
  *
  * ------------------------------------------------------------------- */

html {
  font-size: 10px;
}

@media only screen and (max-width: 400px) {
  html {
    font-size: 9.375px;
  }
}

html,
body {
  height: 100%;
}

body {
  background: #ffffff;
  font-family: "barlow", serif;
  font-size: 1.6rem;
  line-height: 1.875;
  color: #5f5f5f;
  margin: 0;
  padding: 0;
}

/* ------------------------------------------------------------------- 
  * ## links - (_document-setup.scss) 
  * ------------------------------------------------------------------- */

a {
  color: #ffbd52;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus,
a:active {
  color: #191a3f;
}

a:focus {
  outline: none;
}

/* ===================================================================
  * # typography & general theme styles - (_document-setup.scss) 
  * 
  * ------------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6,
.h01,
.h02,
.h03,
.h04,
.h05,
.h06 {
  font-family: "montserrat-medium", sans-serif;
  color: #0d0a0b;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

h1,
.h01,
h2,
.h02,
h3,
.h03,
h4,
.h04 {
  margin-top: 6rem;
  margin-bottom: 1.8rem;
}

@media only screen and (max-width: 600px) {
  h1,
  .h01,
  h2,
  .h02,
  h3,
  .h03,
  h4,
  .h04 {
    margin-top: 5.1rem;
  }
}

h5,
.h05,
h6,
.h06 {
  margin-top: 4.2rem;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 600px) {
  h5,
  .h05,
  h6,
  .h06 {
    margin-top: 3.6rem;
    margin-bottom: 0.9rem;
  }
}

h1,
.h01 {
  font-family: "montserrat-bold", sans-serif;
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}

@media only screen and (max-width: 600px) {
  h1,
  .h01 {
    font-size: 3.3rem;
    letter-spacing: -0.07rem;
  }
}

h2,
.h02 {
  font-size: 3rem;
  line-height: 1.3;
}

h3,
.h03 {
  font-size: 2.4rem;
  line-height: 1.25;
}

h4,
.h04 {
  font-size: 2.1rem;
  line-height: 1.286;
}

h5,
.h05 {
  font-size: 1.6rem;
  line-height: 1.3125;
}

h6,
.h06 {
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
}

p img {
  margin: 0;
}

p.lead {
  font-family: "montserrat-regular", sans-serif;
  font-size: 2rem;
  line-height: 1.8;
  margin-bottom: 3.6rem;
  color: #0d0a0b;
}

@media only screen and (max-width: 800px) {
  p.lead {
    font-size: 1.8rem;
  }
}

em,
i,
strong,
b {
  font-size: inherit;
  line-height: inherit;
  font-style: normal;
  font-weight: normal;
}

em,
i {
  font-family: "barlow", serif;
  font-style: italic;
}

strong,
b {
  font-family: "barlow", serif;
  font-weight: 700;
}

small {
  font-size: 1.2rem;
  line-height: inherit;
}

blockquote {
  margin: 3.9rem 0;
  padding-left: 4.5rem;
  position: relative;
}

blockquote:before {
  content: "\201C";
  font-size: 10rem;
  line-height: 0px;
  margin: 0;
  color: rgba(0, 0, 0, 0.15);
  font-family: arial, sans-serif;
  position: absolute;
  top: 3.6rem;
  left: 0;
}

blockquote p {
  font-family: "montserrat-regular", sans-serif;
  padding: 0;
  font-size: 2.1rem;
  line-height: 1.857;
  color: #0d0a0b;
}

blockquote cite {
  display: block;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 1.5;
}

blockquote cite:before {
  content: "\2014 \0020";
}

blockquote cite a,
blockquote cite a:visited {
  color: #6c6c6c;
  border: none;
}

abbr {
  font-family: "librebaskerville-bold", serif;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.05rem;
  color: #6c6c6c;
}

var,
kbd,
samp,
code,
pre {
  font-family: Consolas, "Andale Mono", Courier, "Courier New", monospace;
}

pre {
  padding: 2.4rem 3rem 3rem;
  background: #f1f1f1;
  overflow-x: auto;
}

code {
  font-size: 1.4rem;
  margin: 0 0.2rem;
  padding: 0.3rem 0.6rem;
  white-space: nowrap;
  background: #f1f1f1;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
}

pre > code {
  display: block;
  white-space: pre;
  line-height: 2;
  padding: 0;
  margin: 0;
}

pre.prettyprint > code {
  border: none;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
  text-decoration: none;
}

mark {
  background: #ffd900;
  color: #000000;
}

hr {
  border: solid rgba(0, 0, 0, 0.1);
  border-width: 1px 0 0;
  clear: both;
  margin: 2.4rem 0 1.5rem;
  height: 0;
}

/* ------------------------------------------------------------------- 
  * ## Lists - (_document-setup.scss)  
  * ------------------------------------------------------------------- */

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

li {
  display: list-item;
}

ol,
ul {
  margin-left: 1.7rem;
}

ul li {
  padding-left: 0.4rem;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin: 0.6rem 0 0.6rem 1.7rem;
}

ul.disc li {
  display: list-item;
  list-style: none;
  padding: 0 0 0 0.8rem;
  position: relative;
}

ul.disc li::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #862121;
  position: absolute;
  left: -17px;
  top: 11px;
  vertical-align: middle;
}

dt {
  margin: 0;
  color: #862121;
}

dd {
  margin: 0 0 0 2rem;
}

/* ------------------------------------------------------------------- 
  * ## responsive video container - (_document-setup)
  * ------------------------------------------------------------------- */

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ------------------------------------------------------------------- 
  * ## floated image - (_document-setup)  
  * ------------------------------------------------------------------- */

img.pull-right {
  margin: 1.5rem 0 0 3rem;
}

img.pull-left {
  margin: 1.5rem 3rem 0 0;
}

/* ------------------------------------------------------------------- 
  * ## tables - (_document-setup.scss)  
  * ------------------------------------------------------------------- */

table {
  border-width: 0;
  width: 100%;
  max-width: 100%;
  font-family: "librebaskerville-regular", serif;
}

th,
td {
  padding: 1.5rem 3rem;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}

th {
  color: #0d0a0b;
  font-family: "montserrat-bold", sans-serif;
}

td {
  line-height: 1.5;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* ------------------------------------------------------------------- 
  * ## Spacing - (_document-setup.scss)  
  * ------------------------------------------------------------------- */

button,
.btn {
  margin-bottom: 1.2rem;
}

fieldset {
  margin-bottom: 1.5rem;
}

input,
textarea,
select,
pre,
blockquote,
figure,
table,
p,
ul,
ol,
dl,
form,
.video-container,
.ss-custom-select {
  margin-bottom: 3rem;
}

/* ------------------------------------------------------------------- 
  * ## pace.js styles - minimal  - (_document-setup.scss)
  * ------------------------------------------------------------------- */

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #cf8402;
  position: fixed;
  z-index: 900;
  top: 0;
  right: 100%;
  width: 100%;
  height: 4px;
}

.oldie .pace {
  display: none;
}

/* ===================================================================
  * preloader - (_preloader-2.scss)
  *
  * ------------------------------------------------------------------- */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #276fb3;
  /* preloader fix colours! */
  z-index: 800;
  height: 100%;
  width: 100%;
}

.no-js #preloader,
.oldie #preloader {
  display: none;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  padding: 0;
  background-color: #b37715;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

/* ===================================================================
  * # forms - (_forms.scss)
  *
  * ------------------------------------------------------------------- */

fieldset {
  border: none;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  display: block;
  height: 6rem;
  padding: 1.5rem 0;
  border: 0;
  outline: none;
  color: #333333;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.5rem;
  line-height: 3rem;
  max-width: 100%;
  background: transparent;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ss-custom-select {
  position: relative;
  padding: 0;
}

.ss-custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  margin: 0;
  line-height: 3rem;
  vertical-align: middle;
}

.ss-custom-select select option {
  padding-left: 2rem;
  padding-right: 2rem;
}

.ss-custom-select select::-ms-expand {
  display: none;
}

.ss-custom-select::after {
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 8px;
  width: 8px;
  margin-top: -7px;
  pointer-events: none;
  position: absolute;
  right: 2.4rem;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

/* IE9 and below */

.oldie .ss-custom-select::after {
  display: none;
}

textarea {
  min-height: 25rem;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  color: #000000;
  border-bottom: 2px solid black;
}

label,
legend {
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 0.9rem;
  line-height: 1.714;
  color: #0d0a0b;
  display: block;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

label > .label-text {
  display: inline-block;
  margin-left: 1rem;
  font-family: "montserrat-regular", sans-serif;
  line-height: inherit;
}

label > input[type="checkbox"],
label > input[type="radio"] {
  margin: 0;
  position: relative;
  top: 0.15rem;
}

/* ------------------------------------------------------------------- 
  * ## style placeholder text - (_forms.scss)
  * ------------------------------------------------------------------- */

::-webkit-input-placeholder {
  color: #6c6c6c;
}

:-moz-placeholder {
  color: #6c6c6c;
  /* Firefox 18- */
}

::-moz-placeholder {
  color: #6c6c6c;
  /* Firefox 19+ */
}

:-ms-input-placeholder {
  color: #6c6c6c;
}

.placeholder {
  color: #6c6c6c !important;
}

/* ------------------------------------------------------------------- 
  * ## Change Autocomplete styles in Chrome - (_forms.scss)
  * ------------------------------------------------------------------- */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #cf3e3e;
  transition: background-color 5000s ease-in-out 0s;
}

/* ===================================================================
  * # buttons - (_buttons.scss)
  *
  * ------------------------------------------------------------------- */

.btn,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  font-family: "montserrat-semibold", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  height: 5.4rem;
  line-height: calc(5.4rem - 0.6rem);
  padding: 0 3rem;
  margin: 0 0.3rem 1.2rem 0;
  color: #151515;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #c5c5c5;
  border: 0.3rem solid #c5c5c5;
}

.btn:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.btn:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  background-color: #b8b8b8;
  border-color: #b8b8b8;
  color: #000000;
  outline: 0;
}

/* button primary
  * ------------------------------------------------- */

.btn.btn--primary,
button.btn--primary,
input[type="submit"].btn--primary,
input[type="reset"].btn--primary,
input[type="button"].btn--primary {
  background: #cf8402;
  border-color: #cf8402;
  color: #ffffff;
}

.btn.btn--primary:hover,
button.btn--primary:hover,
input[type="submit"].btn--primary:hover,
input[type="reset"].btn--primary:hover,
input[type="button"].btn--primary:hover,
.btn.btn--primary:focus,
button.btn--primary:focus,
input[type="submit"].btn--primary:focus,
input[type="reset"].btn--primary:focus,
input[type="button"].btn--primary:focus {
  background: #c77e00;
  border-color: #c77e00;
}

/* button modifiers
  * ------------------------------------------------- */

.btn.full-width,
button.full-width {
  width: 100%;
  margin-right: 0;
}

.btn--medium,
button.btn--medium {
  height: 5.7rem !important;
  line-height: calc(5.7rem - 0.6rem) !important;
}

.btn--large,
button.btn--large {
  height: 6rem !important;
  line-height: calc(6rem - 0.6rem) !important;
}

.btn--stroke,
button.btn--stroke {
  background: transparent !important;
  border: 0.3rem solid #000000;
  color: #000000;
}

.btn--stroke:hover,
button.btn--stroke:hover {
  border: 0.3rem solid #862121;
  color: #862121;
}

.btn--pill,
button.btn--pill {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 1000px !important;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* =================================================================== 
  * # additional components - (_others.scss)
  *
  * ------------------------------------------------------------------- */

/* ------------------------------------------------------------------- 
  * ## alert box - (_alert-box.scss)
  * ------------------------------------------------------------------- */

.alert-box {
  padding: 2.1rem 4rem 2.1rem 3rem;
  position: relative;
  margin-bottom: 3rem;
  border-radius: 3px;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.5rem;
  line-height: 1.6;
}

.alert-box__close {
  position: absolute;
  right: 1.8rem;
  top: 1.8rem;
  cursor: pointer;
}

.alert-box__close.im {
  font-size: 12px;
}

.alert-box--error {
  background-color: #ffd1d2;
  color: #e65153;
}

.alert-box--success {
  background-color: #c8e675;
  color: #758c36;
}

.alert-box--info {
  background-color: #d7ecfb;
  color: #4a95cc;
}

.alert-box--notice {
  background-color: #fff099;
  color: #bba31b;
}

/* ------------------------------------------------------------------- 
  * ## additional typo styles - (_additional-typo.scss)
  * ------------------------------------------------------------------- */

/* drop cap 
  * ----------------------------------------------- */

.drop-cap:first-letter {
  float: left;
  margin: 0;
  padding: 1.5rem 0.6rem 0 0;
  font-size: 8.4rem;
  font-family: "montserrat-bold", sans-serif;
  line-height: 6rem;
  text-indent: 0;
  background: transparent;
  color: #000000;
}

/* line definition style 
  * ----------------------------------------------- */

.lining dt,
.lining dd {
  display: inline;
  margin: 0;
}

.lining dt + dt:before,
.lining dd + dt:before {
  content: "\A";
  white-space: pre;
}

.lining dd + dd:before {
  content: ", ";
}

.lining dd + dd:before {
  content: ", ";
}

.lining dd:before {
  content: ": ";
  margin-left: -0.2em;
}

/* dictionary definition style 
  * ----------------------------------------------- */

.dictionary-style dt {
  display: inline;
  counter-reset: definitions;
}

.dictionary-style dt + dt:before {
  content: ", ";
  margin-left: -0.2em;
}

.dictionary-style dd {
  display: block;
  counter-increment: definitions;
}

.dictionary-style dd:before {
  content: counter(definitions, decimal) ". ";
}

/** 
  * Pull Quotes
  * -----------
  * markup:
  *
  * <aside class="pull-quote">
  *		<blockquote>
  *			<p></p>
  *		</blockquote>
  *	</aside>
  *
  * --------------------------------------------------------------------- */

.pull-quote {
  position: relative;
  padding: 2.1rem 3rem 2.1rem 0px;
}

.pull-quote:before,
.pull-quote:after {
  height: 1em;
  position: absolute;
  font-size: 10rem;
  font-family: Arial, Sans-Serif;
  color: rgba(0, 0, 0, 0.15);
}

.pull-quote:before {
  content: "\201C";
  top: -3.6rem;
  left: 0;
}

.pull-quote:after {
  content: "\201D";
  bottom: 3.6rem;
  right: 0;
}

.pull-quote blockquote {
  margin: 0;
}

.pull-quote blockquote:before {
  content: none;
}

/** 
  * Stats Tab
  * ---------
  * markup:
  *
  * <ul class="stats-tabs">
  *		<li><a href="#">[value]<em>[name]</em></a></li>
  *	</ul>
  *
  * Extend this object into your markup.
  *
  * --------------------------------------------------------------------- */

.stats-tabs {
  padding: 0;
  margin: 3rem 0;
}

.stats-tabs li {
  display: inline-block;
  margin: 0 1.5rem 3rem 0;
  padding: 0 1.5rem 0 0;
  border-right: 1px solid rgba(13, 10, 11, 0.15);
}

.stats-tabs li:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

.stats-tabs li a {
  display: inline-block;
  font-size: 2.5rem;
  font-family: "montserrat-semibold", sans-serif;
  border: none;
  color: #151515;
}

.stats-tabs li a:hover {
  color: #862121;
}

.stats-tabs li a em {
  display: block;
  margin: 0.6rem 0 0 0;
  font-size: 1.4rem;
  font-family: "montserrat-regular", sans-serif;
  color: #6c6c6c;
}

/* ------------------------------------------------------------------- 
  * ## skillbars - (_skillbars.scss)
  * ------------------------------------------------------------------- */

.skill-bars {
  list-style: none;
  margin: 6rem 0 3rem;
}

.skill-bars li {
  height: 0.6rem;
  background: #c9c9c9;
  width: 100%;
  margin-bottom: 6.9rem;
  padding: 0;
  position: relative;
}

.skill-bars li strong {
  position: absolute;
  left: 0;
  top: -3rem;
  font-family: "montserrat-extrabold", sans-serif;
  color: #0d0a0b;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.skill-bars li .progress {
  background: #000000;
  background-image: linear-gradient(to right, #191a3f, #2d462c);
  position: relative;
  height: 100%;
}

.skill-bars li .progress span {
  position: absolute;
  right: 0;
  top: -3.6rem;
  display: block;
  font-family: "montserrat-regular", sans-serif;
  color: #ffffff;
  font-size: 1.1rem;
  line-height: 1;
  background: #000000;
  padding: 0.6rem 0.6rem;
  border-radius: 3px;
}

.skill-bars li .progress span::after {
  position: absolute;
  left: 50%;
  bottom: -5px;
  margin-left: -5px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #000000;
  content: "";
}

.skill-bars li .percent5 {
  width: 5%;
}

.skill-bars li .percent10 {
  width: 10%;
}

.skill-bars li .percent15 {
  width: 15%;
}

.skill-bars li .percent20 {
  width: 20%;
}

.skill-bars li .percent25 {
  width: 25%;
}

.skill-bars li .percent30 {
  width: 30%;
}

.skill-bars li .percent35 {
  width: 35%;
}

.skill-bars li .percent40 {
  width: 40%;
}

.skill-bars li .percent45 {
  width: 45%;
}

.skill-bars li .percent50 {
  width: 50%;
}

.skill-bars li .percent55 {
  width: 55%;
}

.skill-bars li .percent60 {
  width: 60%;
}

.skill-bars li .percent65 {
  width: 65%;
}

.skill-bars li .percent70 {
  width: 70%;
}

.skill-bars li .percent75 {
  width: 75%;
}

.skill-bars li .percent80 {
  width: 80%;
}

.skill-bars li .percent85 {
  width: 85%;
}

.skill-bars li .percent90 {
  width: 90%;
}

.skill-bars li .percent95 {
  width: 95%;
}

.skill-bars li .percent100 {
  width: 100%;
}

/* ===================================================================
  * # common and reusable theme styles  - (_common-styles.scss)
  *
  * ------------------------------------------------------------------- */

.wide {
  max-width: 1400px;
}

.narrow {
  max-width: 800px;
}

/* ------------------------------------------------------------------- 
  * ## section intro - (_common-styles.scss)
  * ------------------------------------------------------------------- */

.section-intro {
  text-align: center;
  position: relative;
}

.section-intro h1 {
  font-family: "montserrat-semibold", sans-serif;
  font-size: 4.8rem;
  line-height: 1.375;
  margin-top: 0;
  color: rgb(0, 101, 188);
}

.section-intro h3 {
  font-family: "montserrat-semibold", sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  margin-top: 0;
  margin-bottom: 0;
}

.section-intro.has-bottom-sep {
  padding-bottom: 1.5rem;
  position: relative;
}

.section-intro.has-bottom-sep::after {
  display: inline-block;
  height: 1px;
  width: 240px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* ------------------------------------------------------------------- 
  * responsive:
  * section-intro
  * ------------------------------------------------------------------- */

@media only screen and (max-width: 800px) {
  .section-intro h1 {
    font-size: 4.2rem;
  }
  .section-intro h3 {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 600px) {
  .section-intro h1 {
    font-size: 3.6rem;
  }
  .section-intro h3 {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 400px) {
  .section-intro h1 {
    font-size: 3.3rem;
  }
  .section-intro h3 {
    font-size: 1.6rem;
  }
}

/* ===================================================================
  * # cta - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */

.s-cta {
  margin-top: -9rem;
  padding-top: 0;
  padding-bottom: 9rem;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

.cta__content {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cta__content h2 a {
  color: #0d0a0b;
}

.cta__content span {
  color: #191a3f;
}

/* ===================================================================
  * # blog styles - (_blog.scss)
  *
  * ------------------------------------------------------------------- */

article.blog-single {
  background-color: #ffffff;
}

article.blog-single .blog-content {
  padding-top: 7.2rem;
  padding-bottom: 12rem;
}

/* -------------------------------------------------------------------
  * ## blog page header
  * ------------------------------------------------------------------- */

.page-header {
  background-color: #0d0a0b;
  background-repeat: no-repeat;
  background-position: center, center;
  padding-top: 20.4rem;
  padding-bottom: 7.2rem;
  position: relative;
  color: rgba(255, 255, 255, 0.5);
  min-height: 50.4rem;
}

.page-header--single {
  padding-top: 22.8rem;
  padding-bottom: 7.2rem;
  text-align: center;
}

.page-header::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  background-color: #0d0a0b;
}

.page-header::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: -moz-linear-gradient(
    top,
    transparent 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    transparent 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
  /* IE6-9 */
}

.page-header__content {
  font-family: "montserrat-regular", sans-serif;
  font-size: 2rem;
  line-height: 1.8;
  position: relative;
  max-width: 900px;
  z-index: 2;
}

.page-header__content p {
  padding-right: 3rem;
}

.page-header__info {
  margin-bottom: 1.2rem;
}

.page-header__info .div:last-child {
  margin-right: 0;
}

.page-header__cat,
.page-header__date {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 0.3rem;
}

.page-header__cat {
  font-size: 1.4rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
  color: rgba(255, 255, 255, 0.8);
  font-family: "montserrat-bold", sans-serif;
}

.page-header__cat a {
  color: rgba(255, 255, 255, 0.8);
}

.page-header__cat a:hover,
.page-header__cat a:focus,
.page-header__cat a:active {
  color: white;
}

.page-header__cat a::after {
  content: ", ";
}

.page-header__cat a:last-child::after {
  display: none;
}

.page-header__date {
  font-family: "montserrat-light", sans-serif;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.4);
}

.page-header__date a {
  color: rgba(255, 255, 255, 0.4);
}

.page-header__date a:hover,
.page-header__date a:focus,
.page-header__date a:active {
  color: white;
}

.page-header__title {
  color: #ffffff;
  position: relative;
  font-family: "librebaskerville-bold", serif;
  font-size: 6.6rem;
  line-height: 1.227;
  margin-top: 0;
  margin-bottom: 3.6rem;
}

.page-header__title a {
  color: #ffffff;
}

.page-header__meta {
  list-style: none;
  margin-left: 0;
  padding-top: 2.4rem;
  font-size: 1.6rem;
  position: relative;
}

.page-header__meta::before {
  display: block;
  content: "";
  height: 1px;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -50px;
}

.page-header__meta li {
  display: inline-block;
  padding-left: 0;
  margin-right: 2rem;
}

.page-header__meta li::last-child {
  margin-right: 0;
}

.page-header__meta span,
.page-header__meta a {
  color: #ffffff;
}

.page-header__btn {
  border-color: #ffffff !important;
  color: #ffffff;
  letter-spacing: 0.25rem;
}

.page-header__btn:hover,
.page-header__btn:focus {
  background: #ffffff !important;
  color: #000000;
}

/* ------------------------------------------------------------------- 
  * responsive:
  * blog page header
  * ------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {
  .page-header__content {
    max-width: 800px;
    font-size: 1.7rem;
  }
  .page-header__cat {
    font-size: 1.3rem;
  }
  .page-header__title {
    font-size: 6rem;
  }
}

@media only screen and (max-width: 900px) {
  .page-header__content {
    max-width: 700px;
  }
}

@media only screen and (max-width: 800px) {
  .page-header--single {
    padding-top: 18rem;
  }
  .page-header__title {
    font-size: 5.4rem;
  }
}

@media only screen and (max-width: 600px) {
  .page-header {
    padding-top: 18rem;
  }
  .page-header__content p {
    padding-right: 0;
  }
  .page-header__title {
    font-size: 4.8rem;
  }
}

@media only screen and (max-width: 500px) {
  .page-header__title {
    font-size: 4.2rem;
  }
}

@media only screen and (max-width: 400px) {
  .page-header__content {
    font-size: 1.6rem;
    line-height: 1.875;
  }
  .page-header__title {
    font-size: 3.8rem;
  }
}

/* -------------------------------------------------------------------
  * ## blog content - _blog.scss
  * ------------------------------------------------------------------- */

.blog-content-wrap {
  background-color: #ffffff;
  padding-top: 4.2rem;
  padding-bottom: 12rem;
}

.blog-content {
  max-width: 1000px;
}

.blog-content__main {
  padding-left: 6rem;
  padding-right: 6rem;
}

.blog-content__main img {
  max-width: 1000px;
  margin: 3rem 0 3rem -6rem;
}

/* post tags
  * ------------------------------------------------- */

.blog-content__tags {
  margin-top: 6rem;
  font-family: "montserrat-regular", sans-serif;
}

.blog-content__tags span {
  font-family: "montserrat-bold", sans-serif;
  color: #0d0a0b;
  position: relative;
  padding-right: 55px;
  margin-right: 1.5rem;
  margin-top: 1.2rem;
}

.blog-content__tags span::after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  right: 0;
}

.blog-content__tags .blog-content__tag-list {
  display: inline-block;
  padding-right: 0;
  margin-right: 0;
}

.blog-content__tags .blog-content__tag-list::after {
  display: none;
}

.blog-content__tags .blog-content__tag-list a {
  display: inline-block;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.3rem;
  line-height: inherit;
  color: #797979;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 0 10px;
}

.blog-content__tags .blog-content__tag-list a:hover,
.blog-content__tags .blog-content__tag-list a:focus {
  background-color: #0d0a0b;
  border-color: #0d0a0b;
  color: #ffffff;
}

.blog-content__tags .blog-content__tag-list a:last-child {
  margin-right: 0;
}

/* blog single page navigation
  * ------------------------------------------------- */

.blog-content__pagenav {
  margin-top: 7.8rem;
  padding-top: 1.8rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.blog-content__nav {
  padding-right: 220px;
}

.blog-content__nav:after {
  content: "";
  display: table;
  clear: both;
}

.blog-content__nav span {
  display: block;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.6rem;
  color: #797979;
  margin-bottom: 1.2rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.blog-content__nav a {
  font-family: "montserrat-bold", sans-serif;
  font-size: 2.2rem;
  line-height: 1.5;
  color: #0d0a0b;
}

.blog-content__nav a:hover span,
.blog-content__nav a:focus span {
  color: #0d0a0b;
}

.blog-content__prev,
.blog-content__next {
  width: 50%;
  float: left;
  padding-right: 2rem;
}

.blog-content__all {
  text-align: right;
  width: 210px;
  position: absolute;
  top: 2.1rem;
  right: 0;
}

.blog-content__all .im {
  font-size: 1.4rem;
  line-height: 1;
  margin-right: 8px;
  position: relative;
  top: 2px;
}

/* blog entries navigation
  * ------------------------------------------------- */

.blog-entries-nav {
  text-align: center;
  max-width: 600px;
  padding-top: 3.6rem;
}

.blog-entries-nav .btn {
  margin-right: 3rem;
  min-width: 150px;
}

.blog-entries-nav .btn:last-child {
  margin-right: 0;
}

.blog-entries-nav .im {
  font-size: 1.3rem;
  line-height: 1;
  position: relative;
  top: 2px;
}

/* ------------------------------------------------------------------- 
  * responsive:
  * blog content
  * ------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {
  .blog-content {
    max-width: 900px;
  }
  .blog-content__main {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .blog-content__main img {
    max-width: 900px;
    margin: 3rem 0 3rem -5rem;
  }
}

@media only screen and (max-width: 1000px) {
  .blog-content {
    max-width: 800px;
  }
  .blog-content__main {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .blog-content__main img {
    max-width: 800px;
    margin: 3rem 0 3rem -4rem;
  }
  .blog-content__prev,
  .blog-content__next {
    width: 100%;
    float: none;
    padding: 0;
    text-align: left;
    margin-bottom: 1.8rem;
  }
}

@media only screen and (max-width: 900px) {
  .blog-content__main {
    padding-left: 15px;
    padding-right: 15px;
  }
  .blog-content__main img {
    max-width: 100%;
    margin: 3rem 0 3rem;
  }
}

@media only screen and (max-width: 800px) {
  .blog-content__nav {
    padding-right: 0;
  }
  .blog-content__all {
    position: static;
    text-align: left;
    width: auto;
    margin-top: 1.8rem;
  }
}

@media only screen and (max-width: 600px) {
  .blog-content__prev,
  .blog-content__next,
  .blog-content__all {
    text-align: center;
  }
  .blog-content__main {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .blog-entries-nav .btn {
    margin-right: 0;
    min-width: none;
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .blog-content__main {
    padding-left: 0;
    padding-right: 0;
  }
  .blog-content__all .btn {
    width: 100%;
  }
}

/* -------------------------------------------------------------------
  * ## comments - _blog.scss
  * ------------------------------------------------------------------- */

.comments-wrap {
  background-color: #f0f0f0;
  padding: 8.4rem 0 9rem;
}

/* comments
  * -------------------------------------------------- */

#comments {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  max-width: 940px;
}

#comments h3 {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

#comments ol,
#comments ul {
  list-style: none;
}

.commentlist {
  margin: 3rem 0 5.4rem;
  padding: 0;
}

.commentlist > .comment {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 1.8rem 0;
  padding-left: 14%;
}

.comment__avatar {
  position: absolute;
  left: 0;
  display: block;
  height: 6rem;
  width: 6rem;
}

.comment__avatar img {
  margin-top: 0.6rem;
  height: 6rem;
  width: 6rem;
  border-radius: 100%;
}

.comment__info cite {
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  color: #0d0a0b;
}

.comment__meta {
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.45rem;
  line-height: 3rem;
  font-style: normal;
  display: block;
  color: rgba(0, 0, 0, 0.7);
}

.comment__meta .reply {
  font-family: "montserrat-bold", sans-serif;
}

.comment__meta .sep {
  margin: 0 5px;
}

.comment__text {
  clear: both;
  margin: 1.8rem 0 0 0;
  padding: 0;
}

.comment ul.children {
  margin: 0;
  padding: 1.8rem 0 0 0;
}

.comment ul.children li {
  padding-left: 5%;
  padding-top: 1.8rem;
}

/* comments form
  * ---------------------------- */

#comments .respond {
  padding-top: 4.2rem;
}

#comments form {
  padding-top: 3rem;
}

#comments form .form-field {
  margin-bottom: 3.9rem;
}

/* ------------------------------------------------------------------- 
  * responsive:
  * comments
  * ------------------------------------------------------------------- */

@media only screen and (max-width: 800px) {
  #comments form button {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  #comments h3 {
    text-align: center;
  }
  .commentlist > .comment {
    padding-left: 0;
  }
  .commentlist .comment__avatar {
    display: none;
  }
}
