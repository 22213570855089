/* ===================================================================
  * # works - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */

.s-works {
  padding-top: 15rem;
  padding-bottom: 15.6rem;
  background-color: #f1f1f1;
  position: relative;
}

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

body {
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  padding-top: 72px;
}

* {
  outline: none;
}

.inner button {
  -webkit-appearance: none;
          appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
         filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
          transition: all .5s ease;
  border: none;
  background: #ffad1f;
  text-transform: uppercase;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 13px;
  cursor: cursor;
}
.inner a{
  color: black;
}

button:hover {
  background: #c77e00;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  height: 70vh;
  background-size: cover !important;
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
}

.previousButton, .nextButton {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
    background: url('https://svgshare.com/i/KbT.svg') no-repeat center center / 30px;
		width: 32px;
		height: 32px;
		text-indent: -9999px;
    cursor: pointer;
}

.previousButton:hover, .nextButton:hover {
  background: url('https://www.flaticon.com/free-icon/left-arrow_271220') no-repeat center center / 16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.slider-content .inner button{
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

@media (max-height: 500px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }    
}

@media (max-width: 640px) {
  .slider-wrapper, .slide {
    height: calc(80vh - 75px);
  }    
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }    
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

/* ===================================================================
  * # slider-animations
  *
  * ------------------------------------------------------------------- */

.slide h1 {
	transition: all 0.3s ease;
	-webkit-transform: translateY(-20px);
					transform: translateY(-20px);
	opacity: 0;
}

.slide button {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide p {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition-delay: .9s;
					transition-delay: .9s;
					opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	-webkit-transition-delay: 1.1s;
					transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	-webkit-transition-delay: 1.3s;
					transition-delay: 1.3s;
}

.slide.animateOut h1 {
	-webkit-transition-delay: .3s;
					transition-delay: .3s;
}

.slide.animateOut p {
	-webkit-transition-delay: .2s;
					transition-delay: .2s;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * works
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 1700px) {
  .masonry-wrap {
    width: 1400px;
  }
  .masonry .grid-sizer,
  .masonry__brick {
    width: 350px;
  }
}

@media only screen and (max-width: 1500px) {
  .masonry-wrap {
    width: 1200px;
  }
  .masonry .grid-sizer,
  .masonry__brick {
    width: 400px;
  }
}

@media only screen and (max-width: 1300px) {
  .masonry-wrap {
    width: 999px;
  }
  .masonry .grid-sizer,
  .masonry__brick {
    width: 333px;
  }
}

@media only screen and (max-width: 1099px) {
  .masonry-wrap {
    width: 90%;
    max-width: 800px;
  }
  .masonry .grid-sizer,
  .masonry__brick {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .masonry .grid-sizer,
  .masonry__brick {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .masonry-wrap {
    width: auto;
  }
}
