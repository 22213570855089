/* ===================================================================
  * # header styles - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */

.s-header {
  width: 100%;
  height: 72px;
  background-color: transparent;
  position: absolute;
  top: 0;
  z-index: 500;
}

.s-header.offset {
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.s-header.scrolling {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.s-header.sticky {
  position: fixed;
  top: 0;
  background-color: #0065bc !important;
  opacity: 0;
  visibility: hidden;
  z-index: 600;
}

.s-header.sticky .header-logo a {
  margin: 0;
}

.s-header.sticky .header-nav-wrap {
  top: 0;
}

.s-header.sticky.scrolling {
  opacity: 1;
  visibility: visible;
}

/* -------------------------------------------------------------------
 * ## header logo - (_site-layout.css) 
 * ------------------------------------------------------------------- */

.header-logo {
  display: inline-block;
  position: absolute;
  margin: 0;
  padding: 0;
  left: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 501;
}

.header-logo a {
  display: block;
  font-family: "tajawal", sans-serif;
  font-size: large;
  margin: 9px 0 0 0;
  padding: 0;
  outline: 0;
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* ------------------------------------------------------------------- 
 * ## mobile menu toggle - (_site-layout.css) 
 * ------------------------------------------------------------------- */

.header-menu-toggle {
  display: none;
  position: absolute;
  right: 29px;
  top: 18px;
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-menu-toggle:hover,
.header-menu-toggle:focus {
  color: #ffffff;
}

.header-menu-toggle span {
  display: block;
  width: 24px;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  left: 9px;
  top: 50%;
  right: auto;
  bottom: auto;
  background-color: white;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.header-menu-toggle span::before,
.header-menu-toggle span::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: inherit;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.header-menu-toggle span::before {
  top: -9px;
}

.header-menu-toggle span::after {
  bottom: -9px;
}

.s-header.sticky .header-menu-toggle {
  top: 12px;
}

.header-menu-toggle.is-clicked span {
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.header-menu-toggle.is-clicked span::before,
.header-menu-toggle.is-clicked span::after {
  background-color: white;
}

.header-menu-toggle.is-clicked span::before {
  top: 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.header-menu-toggle.is-clicked span::after {
  bottom: 0;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

/* ------------------------------------------------------------------- 
 * ## navigation - (_site-layout.scss) 
 * ------------------------------------------------------------------- */

.header-nav-wrap {
  font-family: "montserrat-bold", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  position: absolute;
  top: 9px;
  right: 50px;
}

.header-nav {
  display: inline-block;
  list-style: none;
  margin: 0;
  height: 72px;
}

.header-nav li {
  display: inline-block;
  padding-left: 0;
  margin-right: 2rem;
}

.header-nav li a {
  display: block;
  line-height: 72px;
  color: #ffffff;
}

/* .header-nav li.current a {
    font-family: "montserrat-extrabold", sans-serif;
    color: #af2b2b;
} */

/* ------------------------------------------------------------------- 
 * responsive:
 * header
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 800px) {
  .header-logo {
    left: 40px;
  }
  .header-nav-wrap {
    display: none;
    height: auto;
    width: 100%;
    right: auto;
    top: 0;
    left: 0;
    background-color: #000000;
    padding: 120px 40px 21px;
  }
  .header-nav-wrap .header-nav {
    display: block;
    height: auto;
    margin: 0 0 4.2rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
  }
  .header-nav-wrap .header-nav li {
    display: block;
    margin: 0;
    padding: 0;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.07);
  }
  .header-nav-wrap .header-nav li a {
    padding: 18px 0;
    line-height: 18px;
  }
  .header-menu-toggle {
    display: block;
  }
}

/* -------------------------------------------------------------------
 * make sure the menu is visible on larger screens
 * ------------------------------------------------------------------- */

@media only screen and (min-width: 801px) {
  .header-nav-wrap {
    display: block !important;
  }
}
