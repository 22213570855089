#stack a{
  color: #0065bc;
}

#stack a:hover{
  color: #191a3f;
}

.s-stack {
  padding-top: 15rem;
  padding-bottom: 9.6rem;
  background-color: #FFFFFF;
  position: relative;
}

.blog-list {
  margin-top: 6rem;
  margin-left: 0;
}

.blog-list article {
  margin-bottom: 7.2rem;
}

.blog-list article:nth-child(2n+1) {
  padding-right: 50px;
}

.blog-list article:nth-child(2n+2) {
  padding-left: 50px;
}

.blog-list h2 {
  font-family: "montserrat-medium", sans-serif;
  font-size: 3rem;
  line-height: 1.2;
  margin-top: .3rem;
  margin-bottom: 2.1rem;
  padding-bottom: 2.4rem;
  color: #0D0A0B;
  position: relative;
}

.blog-list h2 a {
  color: #0D0A0B;
}

.blog-list h2::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 80px;
  background-color: rgba(0, 0, 0, 0.15);
}

.blog-list .blog-date {
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.5rem;
  color: #862121;
}

.blog-list .blog-date a {
  color: #862121;
}

.blog-list .blog-date a:hover, 
.blog-list .blog-date a:focus, 
.blog-list .blog-date a:active {
  color: #000000;
}

.blog-list .blog-cat {
  font-family: "montserrat-medium", sans-serif;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: .2rem;
  color: #000000;
  margin-top: -.9rem;
}

.blog-list .blog-cat a {
  color: #000000;
}

.blog-list .blog-cat a:hover, 
.blog-list .blog-cat a:focus, 
.blog-list .blog-cat a:active {
  color: #862121;
}

.blog-list .blog-cat a::after {
  content: ", ";
}

.blog-list .blog-cat a:last-child::after {
  display: none;
}


/* ------------------------------------------------------------------- 
* responsive:
* blog-list
* ------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {
  .blog-list article:nth-child(2n+1) {
      padding-right: 40px;
  }
  .blog-list article:nth-child(2n+2) {
      padding-left: 40px;
  }
}

@media only screen and (max-width: 800px) {
  .blog-list article:nth-child(n) {
      padding: 0 15px;
  }
}

@media only screen and (max-width: 600px) {
  .blog-list article:nth-child(n) {
      padding: 0;
  }
  .blog-list h2 {
      font-size: 2.7rem;
  }
}