/* ===================================================================
  * # about - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */

.s-about {
  padding-top: 15rem;
  padding-bottom: 9rem;
  background-color: #f1f1f1;
  position: relative;
}

.about-content {
  margin-bottom: 3rem;
}

.about-content .left {
  padding-right: 40px;
  text-align: center;
}

.about-content .right {
  padding-left: 40px;
  text-align: center;
  background-size: 30px 30px;
}

.about-content img {
  border-radius: 50%;
  text-align: center;
  width: 75%;
}

.about-content .left img{
  padding-bottom: 3rem;
}

.about-content p {
  text-align: justify;
}

.about-content h3 {
  text-align: justify;
}




/* ------------------------------------------------------------------- 
 * responsive:
 * about
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 1000px) {
  .about-content .left {
    padding-right: 30px;
  }

  .about-content .left img {
    padding-bottom: 0;
  }

  .about-content .right {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 900px) {
  .about-content .left {
    padding-right: 20px;
  }
  .about-content .right {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .about-content {
    max-width: 600px;
  }
  .about-content .left {
    padding-right: 15px;
  }
  .about-content .right {
    padding-left: 15px;
  }
  .about-content .right .timeline {
    margin-top: 0;
  }
  .about-content .left > h3,
  .about-content .right > h3 {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .about-content .left {
    padding-right: 10px;
  }
  .about-content .right {
    padding-left: 10px;
  }
  .timeline__block {
    padding-left: 6rem;
  }
}

@media only screen and (max-width: 400px) {
  .about-content .left {
    padding-right: 0;
  }
  .about-content .right {
    padding-left: 0;
  }
  .timeline__block {
    padding-left: 5.5rem;
  }
}
