/* ===================================================================
  * # contact - (_site-layout.scss)
  *
  * ------------------------------------------------------------------- */

.s-contact {
  background-color: #276fb3;
  /* background-image: url("../images/laptop.jpg"); */
  background-repeat: no-repeat;
  background-position: center, center;
  padding-top: 13.2rem;
  padding-bottom: 12rem;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
}

.s-contact .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-color: #070606;
}

.contact__main img {
  /* float: right; */
  display: block;
  margin: auto;
  width: 50%;
  
}

.s-contact .section-intro h1 {
  color: #ffffff;
}

.s-contact .section-intro h3 {
  color: #ffad1f;
}

.s-contact .section-intro p {
  color: rgba(255, 255, 255, 0.5);
}

.s-contact .section-intro.has-bottom-sep::after {
  background-color: rgba(255, 255, 255, 0.1);
}

.s-contact .form-field {
  position: relative;
}

.s-contact .form-field:after {
  content: "";
  display: table;
  clear: both;
}

.s-contact .form-field label {
  font-family: "montserrat-semibold", sans-serif;
  font-size: 1.1rem;
  line-height: 2.4rem;
  position: absolute;
  bottom: -0.3rem;
  right: 0.6rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 0 2rem;
  margin: 0;
  color: #ffffff;
  background: #862121;
}

.s-contact .form-field label::after {
  content: "";
  position: absolute;
  left: -5px;
  top: 50%;
  margin-top: -6px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid ;
}

.contact__main {
  max-width: 900px;
  position: relative;
}

.contact__main h4 {
  color: #ffffff;
}

.contact__infos {
  font-size: 1.5rem;
}

.message-warning,
.message-success {
  display: none;
  background: #0d0a0b;
  font-size: 1.5rem;
  line-height: 2;
  padding: 3rem;
  margin-bottom: 3.6rem;
  width: 100%;
}

/* --------------------------------------------------------------- 
 * loader animation - (_site-layout.scss)
 * --------------------------------------------------------------- */

.s-loader {
  margin: 1.2rem auto 3rem;
  width: 70px;
  text-align: center;
  -webkit-transform: translateX(0.45rem);
  -ms-transform: translateX(0.45rem);
  transform: translateX(0.45rem);
}

.s-loader > div {
  width: 9px;
  height: 9px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.9rem;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
  animation: bouncedelay 1.4s infinite ease-in-out both;
}

.s-loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.s-loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

/* ------------------------------------------------------------------- 
 * responsive:
 * contact
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 800px) {
  .contact__infos {
    padding-top: 3rem;
  }
}
