 /* ===================================================================
  * # home
  *
  * ------------------------------------------------------------------- */
 
 
.s-home {
  width: 100%;
  height: 100%;
  min-height: 786px;
  background-color: transparent;
  position: relative;
  display: table;
}

.s-home .shadow-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .8;
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
}

.s-home .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background-color: #111111;
}

.no-js .s-home {
  background: #0D0A0B;
}


/* ------------------------------------------------------------------- 
* ## home content - (_site-layout.scss) 
* ------------------------------------------------------------------- */

.home-content {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  padding-bottom: 19.2rem;
  position: relative;
  background-color: #2b92ef;
  /* background-image: url("../images/coffee.jpg"); */
}


.home-content h3 {
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.8rem;
  color: #ffad1f;
  text-transform: uppercase;
  letter-spacing: .5rem;
  position: relative;
  padding-left: 80px;
  margin-left: -30px;
  margin-bottom: .6rem;
}

.home-content h3::before {
  display: block;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #ffad1f;
  margin-top: -1.5px;
  position: absolute;
  left: 0;
  top: 50%;
}

.home-content h1 {
  font-family: "librebaskerville-bold", serif;
  color: #FFFFFF;
  font-size: 6rem;
  line-height: 1.35;
  margin-top: 0;
}

.home-content__main {
  padding-right: 300px;
  position: relative;
}

.home-content__buttons {
  position: absolute;
  right: 0;
  bottom: 2.1rem;
  text-align: center;
}

.home-content__buttons .btn {
  display: block;
  width: 210px;
  border-color: #FFFFFF;
  color: #FFFFFF;
  margin: 1.5rem 0 0 0;
  letter-spacing: .25rem;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.home-content__buttons .btn:hover, 
.home-content__buttons .btn:focus {
  background: #FFFFFF !important;
  color: #000000;
}

.home-content__scroll {
  position: absolute;
  left: .6rem;
  bottom: -13.2rem;
}

.home-content__scroll a {
  font-family: "montserrat-semibold", sans-serif;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: .3rem;
  color: #FFFFFF;
  position: relative;
  display: inline-block;
  line-height: 3rem;
  padding-right: 2rem;
}

.home-content__scroll a::after {
  border-bottom: 2px solid #ffad1f;
  border-right: 2px solid #ffad1f;
  content: '';
  display: block;
  height: 8px;
  width: 8px;
  margin-top: -7px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}


/* ------------------------------------------------------------------- 
* ## home social - (_site-layout.scss) 
* ------------------------------------------------------------------- */

.home-social {
  font-family: "montserrat-bold", sans-serif;
  list-style: none;
  margin: 0;
  padding-bottom: 18rem;
  position: absolute;
  bottom: 0;
  right: 40px;
}

.home-social::after {
  display: block;
  content: "";
  height: 17.4rem;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.08);
  position: absolute;
  bottom: 0;
  right: .75rem;
}

.home-social a {
  color: white;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.home-social li {
  position: relative;
  height: 3.6rem;
}

.home-social i, .home-social span {
  position: absolute;
  top: 0;
  line-height: 3.6rem;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.home-social i {
  font-size: 1.8rem;
  right: 0;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
}

.home-social span {
  color: white;
  right: 3.6rem;
  font-size: 1.3rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.home-social li:hover span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.home-social li:hover i {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}


/* animate .home-content__main
* ------------------------------------------------------------------- */

html.ss-preload .home-content__main {
  opacity: 0;
}

html.ss-loaded .home-content__main {
  animation-duration: 2s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

html.no-csstransitions .home-content__main {
  opacity: 1;
}


/* ------------------------------------------------------------------- 
* ## home animations - (_site-layout.scss) 
* ------------------------------------------------------------------- */

/* bounce */

@-webkit-keyframes bounce {
  0%, 100% {
      -webkit-transform: translate3d(0, -30px, 0);
      -ms-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
  }
  50% {
      -webkit-transform: translate3d(0, -4px, 0);
      -ms-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  0%, 100% {
      -webkit-transform: translate3d(0, -30px, 0);
      -ms-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
  }
  50% {
      -webkit-transform: translate3d(0, -4px, 0);
      -ms-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
  }
}


/* fade in */

@-webkit-keyframes fadeIn {
  from {
      opacity: 0;
      -webkit-transform: translate3d(0, -150%, 0);
      -ms-transform: translate3d(0, -150%, 0);
      transform: translate3d(0, -150%, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
      -webkit-transform: translate3d(0, -150%, 0);
      -ms-transform: translate3d(0, -150%, 0);
      transform: translate3d(0, -150%, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }
}


/* fade out */

@-webkit-keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
      -webkit-transform: translate3d(0, -150%, 0);
      -ms-transform: translate3d(0, -150%, 0);
      transform: translate3d(0, -150%, 0);
  }
}

@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
      -webkit-transform: translate3d(0, -150%, 0);
      -ms-transform: translate3d(0, -150%, 0);
      transform: translate3d(0, -150%, 0);
  }
}


/* ------------------------------------------------------------------- 
* responsive:
* home
* ------------------------------------------------------------------- */

@media only screen and (max-width: 1500px) {
  .home-content h1 {
      font-size: 5.2rem;
  }
  .home-content__main {
      max-width: 1000px;
  }
}

@media only screen and (max-width: 1200px) {
  .home-content h1 {
      font-size: 5rem;
  }
  .home-content br {
      display: none;
  }
  .home-content__main {
      max-width: 800px;
  }
  .home-content__buttons {
      right: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .home-content h1 {
      font-size: 4.8rem;
  }
  .home-content__main {
      padding-right: 40px;
      max-width: 680px;
  }
  .home-content__buttons {
      position: static;
      text-align: left;
  }
  .home-content__buttons .btn {
      display: inline-block;
      margin-right: .9rem;
  }
}

@media only screen and (max-width: 800px) {
  .home-content h3 {
      font-size: 1.6rem;
      padding-left: 50px;
      margin-left: -10px;
  }
  .home-content h3::before {
      width: 40px;
  }
  .home-content h1 {
      font-size: 4.4rem;
  }
  .home-content__main {
      max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .home-content {
      padding-bottom: 15rem;
  }
  .home-content h1 {
      font-size: 4.2rem;
  }
  .home-content__main {
      max-width: 380px;
      padding: 0;
  }
  .home-content__scroll {
      bottom: -9rem;
  }
  .home-social {
      padding-bottom: 10.8rem;
  }
  .home-social::after {
      height: 10.2rem;
  }
}

@media only screen and (max-width: 500px) {
  .s-home {
      min-height: 654px;
  }
  .home-content h3 {
      font-size: 1.5rem;
  }
  .home-content h1 {
      font-size: 3.8rem;
  }
  .home-content__main {
      max-width: none;
      width: 85%;
      margin-top: 150px;
  }
}

@media only screen and (max-width: 400px) {
  .home-content {
      vertical-align: middle;
  }
  .home-content h1 {
      font-size: 3.5rem;
  }
  .home-content__main {
      width: auto;
      margin-left: 25px;
      margin-right: 25px;
  }
  .home-content__buttons .btn {
      width: 100%;
  }
  .home-social {
      display: none;
  }
}
